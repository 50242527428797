import React, { Fragment, useState, useEffect } from 'react'
import imgs from '../../utils/images';
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { base_url } from '../../actions/constants';


const EmailVerified = () => {
    const [validUrl, setValidUrl] = useState(true);
    const [bName, setbName] = useState('');


    const param = useParams();


    useEffect(() => {
        const storedBusinessName = localStorage.getItem('businessName');
        if (storedBusinessName) {
            setbName(storedBusinessName);
        }
    }, []);



    useEffect(() => {
        axios.defaults.baseURL = base_url;

        const verifyEmailUrl = async () => {
            try {
                const { data } = await axios.get(`/api/customers/${param.id}/verify/${param.token}`);
                console.log(data);
                setValidUrl(true);
            } catch (error) {
                console.log(error);
                setValidUrl(false);
            }
        };
        verifyEmailUrl();
    }, [param]);


    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 banner">
                    <p className="heading px-5">Signup/Login</p>
                </div>
            </div>
            <section id="contentarea">
                <div className="container-fluid">
                    <div className="row">
                        <div className="loginbox rounded bg-white">
                            {validUrl ? (
                                <div className="text-center">
                                    <img src={imgs.email_verified} className="my-3" alt="Spania" />
                                    <h3 className="fw-bolder mb-4">Your email address has been verified </h3>
                                    <p className="mb-3">Your account is not active. Please use the link below to login to your account.</p>
                                    <Link to={`/services/${bName}/login`} className="btn btn-dark mt-3 shadow mb-5">Login to Booqy</Link>
                                </div>
                            ) : (
                                <div className='text-center my-5'>
                                    <h2>404 Page Not Found</h2>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default EmailVerified