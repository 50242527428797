import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SidebarMenu from '../SidebarMenu'
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../../layouts/Spinner';

import { getUpcomingAppointments } from '../../../actions/upcoming_appointments';
import { cancelBooking } from '../../../actions/cancel_bookings';

const Upcoming = ({ getUpcomingAppointments, upcoming: { upcoming_appointments, loading }, cancelBooking }) => {
    const { businessName } = useParams();
    const todayDate = new Date();
    const dateString = todayDate.toISOString().split('T')[0];
    const [currency, setCurrency] = useState('');

    console.log(dateString);
    // const time = todayDate.getHours() + ':' + todayDate.getMinutes();

    console.log(upcoming_appointments);

    useEffect(() => {
        setCurrency(localStorage.getItem('currencySymbol'));

        getUpcomingAppointments();
    }, [getUpcomingAppointments]);
    // const cancelBook = async (appointments) => {
    //     console.log(appointments.payment_id);
    //     const paymentId = appointments.payment_id;
    //     const book_id = appointments.book_id;
    //     try {
    //         await cancelBooking({ paymentId, book_id });
    //     } catch (error) {

    //     }
    //     window.location.reload();
    // }

    return (
        <Fragment>
            <section id="contentarea4" className='vh-100'>
                <div className="container">
                    <div className="row">
                        <SidebarMenu />
                        <div className="col-md-9">
                            <div className="card shadow">
                                <h3 className="fw-bolder p-md-4 pt-3">Your upcoming Appointments</h3>
                                <hr className='mb-0 mb-md-4' />
                                <div className="d-none d-md-block">
                                    <table className="table mb-5 ">
                                        <thead>
                                            <tr>
                                                <th scope="row" className="ps-4">Order ID</th>
                                                <th>Appointment Date</th>
                                                <th>Appointment Time</th>
                                                <th>Serivce Charges</th>
                                                <th colspan="2">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? <Spinner /> :
                                                upcoming_appointments.map((appointments) => (
                                                    <tr>
                                                        <td className="ps-4"><Link to={`/${businessName}/upcoming-appointments/${appointments.id}`} >{appointments.id}</Link></td>
                                                        <td>{appointments.book_date}</td>
                                                        <td className="text-center">{appointments.book_time}</td>
                                                        <td>{currency}{appointments.amount}</td>
                                                        <td></td>
                                                        <td></td>
                                                        {/* <td>{(appointments.book_date >= dateString) && <Link to={`/${businessName}/get-availability/${appointments.id}`} className="blulink">Reschedule</Link>}   </td> */}
                                                        {/* 25th aug 2023 , today date : 23rd aug &&  11:45 < 18:00 */}
                                                        {/* 25th aug 2023 , today date : 24th aug &&  11:45 < 11:00 */}
                                                        {/* <td>{(appointments.book_date >= dateString) && <button type="button" onClick={(e) => cancelBook(appointments)} className={`btn p-0`}>Cancel</button>}  </td> */}
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='card d-md-none'>
                                    {loading ? <Spinner /> :
                                        upcoming_appointments.map((appointments) => (
                                            <div className="d-flex justify-content-between p-3 border-bottom" key={appointments.id}>
                                                <div>
                                                    <div className='fw-bold'><Link to={`/${businessName}/upcoming-appointments/${appointments.id}`} >{appointments.id}</Link></div>
                                                    <div className='fw-bold mt-1'>{appointments.book_date}</div>
                                                    <div className='text-secondary'>Appointment Date</div>
                                                    <div className='fw-bold mt-2'>{appointments.book_time}</div>
                                                    <div className='text-secondary'>Appointment Time</div>
                                                </div>
                                                <div>{currency}{appointments.amount}</div>
                                                <div ><span className='text-danger'></span></div>
                                            </div>
                                        ))


                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

Upcoming.propTypes = {
    auth: PropTypes.object.isRequired,
    upcoming: PropTypes.object.isRequired,
    getUpcomingAppointments: PropTypes.func.isRequired,
    cancelBooking: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    upcoming: state.upcoming
});

export default connect(mapStateToProps, { getUpcomingAppointments, cancelBooking })(Upcoming)
