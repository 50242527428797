import React, { Fragment, useEffect } from 'react';
import './App.css';
import Services from './components/services/Services';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import PageNotFound from './components/PageNotFound';
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';


//Redux
import { Provider } from 'react-redux';
import store from './store';
import Schedule from './components/schedule/Schedule';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import EmailVerified from './components/auth/EmailVerified';
// import PrivateRoute from './components/routing/PrivateRoute';
// import Checkout from './components/checkout/Checkout';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth';
import AuthenticatedRoutes from './components/routing/AuthenticatedRoutes';
import ValueProvider from './components/layouts/ValueProvider';
// import OrderSuccess from './components/checkout/OrderSuccess';
// import CustomerDashboard from './components/profile/CustomerDashboard';
// import Upcoming from './components/profile/appointments/Upcoming';
// import Past from './components/profile/appointments/Past';
// import Settings from './components/profile/setttings/Settings';
// import Details from './components/profile/appointments/Details';
// import ReSchedule from './components/schedule/ReSchedule';
// import OrderCancel from './components/checkout/OrderCancel';
// import Cancelled from './components/profile/appointments/Cancelled';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
    if (localStorage.token) {
      document.body.className = 'signin';
      return () => { document.body.className = ''; }
    }
  }, []);
  return (
    <ValueProvider>
      <Provider store={store}>
        <Router>
          <Fragment>
            <Header />
            <Routes>
              <Route path='/:businessName' element={<Services />} />
              <Route path='/:businessName/availability/:formattedDate' element={<Schedule />} />
              <Route path='/:businessName/login' element={<Login />} />
              <Route path='/:businessName/register' element={<Register />} />
              <Route path="/users/:id/verify/:token" element={<EmailVerified />} />
              <Route path='/*' element={<AuthenticatedRoutes />} />
            </Routes>
            <Footer />
          </Fragment>
        </Router>
      </Provider>
    </ValueProvider>
  );
}

export default App;
