import {
    GET_CLIENT_WISE_INFO,
    CLIENT_WISE_INFO_ERROR,
    
} from '../actions/types';


const initialState = { clients: [], client: null, loading: true, error: {} }

export default function client_info(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CLIENT_WISE_INFO:
            return {
                ...state,
                client: payload,
                loading: false
            }
        case CLIENT_WISE_INFO_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }

}