
import PrivateRoute from './PrivateRoute';
import OrderSuccess from '../checkout/OrderSuccess';
import CustomerDashboard from '../profile/CustomerDashboard';
import Upcoming from '../profile/appointments/Upcoming';
import Past from '../profile/appointments/Past';
import Settings from '../profile/setttings/Settings';
import Details from '../profile/appointments/Details';
import ReSchedule from '../schedule/ReSchedule';
import OrderCancel from '../checkout/OrderCancel';
import Cancelled from '../profile/appointments/Cancelled';
import Checkout from '../checkout/Checkout';


function AuthenticatedRoutes() {
    return (
        <>
            <PrivateRoute path='/:businessName/dashboard' element={<CustomerDashboard />} />
            <PrivateRoute path='/:businessName/checkout' element={<Checkout />} />
            <PrivateRoute path='/:businessName/upcoming-appointments' element={<Upcoming />} />
            <PrivateRoute path='/:businessName/upcoming-appointments/:id' element={<Details />} />
            <PrivateRoute path='/:businessName/past-appointments' element={<Past />} />
            <PrivateRoute path='/:businessName/cancelled-appointments' element={<Cancelled />} />
            <PrivateRoute path='/:businessName/account-settings' element={<Settings />} />
            <PrivateRoute path='/:businessName/get-availability/:id' element={<ReSchedule />} />
            <PrivateRoute path='/:businessName/success/:sessionid' element={<OrderSuccess />} />
            <PrivateRoute path='/:businessName/cancelled/:sessionid' element={<OrderCancel />} />
        </>
    );
}

export default AuthenticatedRoutes;