import React, { Fragment, useState, useEffect } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { login, googleLogin } from '../../actions/auth';
import Alert from '../layouts/Alert';
import axios from 'axios';

import PropTypes from 'prop-types'
import { useGoogleLogin } from '@react-oauth/google';
import { FaGoogle } from "react-icons/fa6";



const Login = ({ login, isAuthenticated, googleLogin }) => {
    const [user, setUser] = useState([]);
    // const [profile, setProfile] = useState([]);

    const [cart, setCart] = useState(() => {
        return JSON.parse(localStorage.getItem('addToBag')) || []
    });

    const [formData, setFormData] = useState({
        email: '',
        password: '',

    });
    const { email, password } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = e => {
        e.preventDefault();
        if (email !== '' && password !== '')
            login({ email, password });
    };
    const loginGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            console.log(codeResponse);
            setUser(codeResponse);
        },
        onError: (error) => console.log('Login Failed:', error)
    });
    const { businessName } = useParams();

    useEffect(
        () => {
            localStorage.setItem('businessName', businessName);
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json',
                        }
                    }).then((res) => {
                        // setProfile(res.data);
                        const email = res.data.email;
                        const googleID = res.data.id;
                        const verified_email = res.data.verified_email;
                        const lastName = res.data.family_name;
                        const firstName = res.data.given_name;
                        const name = res.data.name;
                        const picture = res.data.picture;
                        googleLogin({ businessName, email, googleID, verified_email, lastName, firstName, name, picture });

                    }).catch((err) => console.log(err));
            }
        },
        [user, googleLogin, businessName]
    );

    useEffect(() => {
        setCart(JSON.parse(localStorage.getItem('addToBag')));
    });

    //Redirect to checkout if logged in and cart items
    if (isAuthenticated && cart && cart.length > 0) {
        return <Navigate to={`/${businessName}/checkout`} />;
    }

    //Redirect if logged in
    if (isAuthenticated && cart && cart.length < 1) {
        return <Navigate to={`/${businessName}/dashboard`} />;
    }

    return (
        <Fragment>
            <div className="banner">
                <div className="container">
                    <div className="col-md-12 ">
                        <p className="heading">Login</p>
                    </div>
                </div>
            </div>
            <section id='contentarea'>
                <Alert />
                <div className="container">
                    <div className="row">
                        <div className="loginbox rounded bg-white p-4">
                            <div className="text-center">
                                <form className="form" onSubmit={e => onSubmit(e)}>
                                    <div className="col-md-12 text-start">
                                        <p className="mb-3"><label>Email ID <span className="text-danger">*</span></label>
                                            <input type="email" className='form-control form-control-lg' name="email" id='businessEmail' value={email} onChange={e => onChange(e)
                                            } />
                                        </p>
                                        <p>
                                            <label>Password <span className="text-danger">*</span></label>
                                            <input type="password" className="form-control form-control-lg" name="password" id='password' value={password} onChange={e => onChange(e)
                                            } aria-label="Password" />
                                        </p>
                                    </div>
                                    <p className="mb-4"><Link to={`/${businessName}`} className="btn btn-dark-outline mt-3 shadow">CANCEL</Link> <span className="px-2"></span><button type='submit' className="btn btn-dark mt-3 shadow">LOGIN</button></p>
                                    <p className="py-3 text-muted"><button className='btn btn-outline-danger' onClick={() => loginGoogle()}><FaGoogle /> Sign in with Google </button>
                                    </p>

                                    <p className="py-3 text-muted">Don't have an account?</p>
                                    <p className="mb-3"><Link to={`/${businessName}/register`}>Register Now</Link></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

Login.propTypes = {
    setAlert: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    googleLogin: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
};
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps, { setAlert, login, googleLogin })(Login); 
