import {
    GET_CLIENT_WISE_SERVICES, CLIENT_WISE_SERVICE_ERROR
} from '../actions/types';


const initialState = { category_wise_services: [], category_wise_service: null, loading: true, error: {} }

export default function categoryWiseService(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CLIENT_WISE_SERVICES:
            return {
                ...state,
                category_wise_services: payload,
                loading: false
            }

        case CLIENT_WISE_SERVICE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }

}