import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SidebarMenu from '../SidebarMenu'
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../../layouts/Spinner';

import { getCancelledAppointments } from '../../../actions/cancel_bookings';

const Cancelled = ({ getCancelledAppointments, booking: { bookings, loading } }) => {
    const { businessName } = useParams();
    const [currency, setCurrency] = useState('');


    useEffect(() => {
        setCurrency(localStorage.getItem('currencySymbol'));
        getCancelledAppointments();
    }, [getCancelledAppointments]);




    return (
        <Fragment>
            <section id="contentarea4" className='vh-100'>
                <div className="container">
                    <div className="row">
                        <SidebarMenu />
                        <div className="col-md-9">
                            <div className="card shadow">
                                <h3 className="fw-bolder p-md-4 pt-3">Your Cancelled Appointments</h3>
                                <hr className='mb-0 mb-md-4' />
                                <div className="d-none d-md-block">
                                    <table className="table mb-5">
                                        <thead>
                                            <tr>
                                                <th scope="row" className="ps-4">Order ID</th>
                                                <th>Appointment Date</th>
                                                <th>Appointment Time</th>
                                                <th>Serivce Charges</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? <Spinner /> :
                                                bookings.map((appointments) => (
                                                    <tr>
                                                        <td className="ps-4"><Link to={`/${businessName}/upcoming-appointments/${appointments.id}`} >{appointments.id}</Link></td>
                                                        <td>{appointments.book_date}</td>
                                                        <td className="text-center">{appointments.book_time}</td>
                                                        <td>{currency}{appointments.amount}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='card d-md-none'>
                                    {loading ? <Spinner /> :
                                        bookings.map((appointments) => (
                                            <div className="d-flex justify-content-between p-3 border-bottom" key={appointments.id}>
                                                <div>
                                                    <div className='fw-bold'><Link to={`/${businessName}/upcoming-appointments/${appointments.id}`} >{appointments.id}</Link></div>
                                                    <div className='fw-bold mt-1'>{appointments.book_date}</div>
                                                    <div className='text-secondary'>Appointment Date</div>
                                                    <div className='fw-bold mt-2'>{appointments.book_time}</div>
                                                    <div className='text-secondary'>Appointment Time</div>
                                                </div>
                                                <div>{currency}{appointments.amount}</div>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

Cancelled.propTypes = {
    auth: PropTypes.object.isRequired,
    booking: PropTypes.object.isRequired,
    getCancelledAppointments: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    booking: state.booking
});

export default connect(mapStateToProps, { getCancelledAppointments })(Cancelled)
