import axios from 'axios';
import {
    GET_PAST_APPOINTMENTS, PAST_APPOINTMENTS_ERROR
} from './types';

import { base_url } from './constants';

axios.defaults.baseURL = base_url;
//GET Past Appointments
export const getPastAppointments = () => async dispatch => {
    try {
        const res = await axios.get(`/api/appointments/past`);
        dispatch({ type: GET_PAST_APPOINTMENTS, payload: res.data });
    } catch (err) {
        dispatch({
            type: PAST_APPOINTMENTS_ERROR,
        });
    }
}


