import { combineReducers } from "redux";
import alert from './alert';
import auth from './auth';
import category from './category';
import service from './service';
import category_wise_service from './category_wise_service';
import day_week from "./day_week";
import payment from './payment';
import upcoming from "./upcoming_appointment";
import past from "./past_appointment";
import booking from "./cancel_booking";
import client_info from "./client_info";
export default combineReducers({
    alert, auth, category, service, category_wise_service, day_week, payment, upcoming, past, booking,client_info
});