import {
    ADD_ORDER, ORDER_ERROR, ORDER_SUCCESS
} from '../actions/types';


const initialState = { payments: [], payment: null, loading: true, error: {} }

export default function payment(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_ORDER:
            return {
                ...state,
                payment: payload,
                loading: false
            }
        case ORDER_SUCCESS:
            localStorage.removeItem('addToBag');
            localStorage.removeItem('scheduleFullDate');
            localStorage.removeItem('totalHours');
            localStorage.removeItem('totalMinutes');
            localStorage.removeItem('totalBill');
            localStorage.removeItem('checkBoxStatus');
            localStorage.removeItem('scheduleDate');
            return {
                ...state,
                payment: payload,
                loading: false
            }
        case ORDER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }

}