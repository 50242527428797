import clock_icon from "../img/clock-icon.png"
import banner from "../img/banner.jpg"
import octonion_logo from "../img/octonion.png"
// import client_list from "../img/61.svg"
// import services_icon from "../img/62.svg"
// import profile_icon from "../img/63.svg"
// import promote_icon from "../img/64.svg"
// import report_icon from "../img/65.svg"
// import business_icon from "../img/66.svg"
// import support_icon from "../img/67.svg"
import email_verified from "../img/email.png"

const images = { 'clock_icon': clock_icon, 'banner': banner, 'octonion': octonion_logo, 'email_verified': email_verified }

export default images


