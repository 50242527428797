import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Calendar from 'react-calendar';
import { useParams } from 'react-router-dom';
import img from '../../utils/images'
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import Spinner from '../layouts/Spinner';
import { Link } from 'react-router-dom';

import { getUpcomingAppointmentById, updateUpcomingAppointmentById } from '../../actions/upcoming_appointments';

import ServiceItems from '../profile/appointments/ServiceItems';
import Alert from '../layouts/Alert';


const ReSchedule = ({ getUpcomingAppointmentById, updateUpcomingAppointmentById, upcoming: { upcoming_appointment, loading } }) => {
    //Decleration -> Storing locally
    const [date, setDate] = useState(new Date());
    const [getFormattedDate, setGetFormattedDate] = useState(() => {
        return JSON.parse(localStorage.getItem('getScheduleFullDate'))
    });
    const [gTime, setGetTime] = useState(() => {
        return JSON.parse(localStorage.getItem('getScheduleTime'))
    });
    const [getBookId, setBookId] = useState(() => {
        return JSON.parse(localStorage.getItem('getBookId'))
    });
    const [serviceItems, setServiceItems] = useState([]);

    const { id } = useParams();

    const [formData, setFormData] = useState({
        book_id: getBookId,
        book_time: gTime,
        book_date: getFormattedDate,
    });
    const { book_id, book_time, book_date } = formData;


    // const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = e => {
        e.preventDefault();
        updateUpcomingAppointmentById({ book_time, book_date, book_id });
    }



    useEffect(() => {
        getUpcomingAppointmentById(id);
    }, [getUpcomingAppointmentById, id]);


    useEffect(() => {
        if (upcoming_appointment) {
            setBookId(upcoming_appointment.book_id);
            const mydate = new Date(upcoming_appointment.book_date);

            setDate(mydate);
            const latestDate = new Date(
                mydate.getTime() - mydate.getTimezoneOffset() * 60 * 1000
            );
            const formattedLatestDate = latestDate.toISOString().split('T')[0];

            setGetTime(upcoming_appointment.book_time);
            const bookItems = JSON.parse(upcoming_appointment.book_items);
            if (bookItems)
                setServiceItems(bookItems);

            // Update local storage whenever  change
            localStorage.setItem('getBookId', JSON.stringify(upcoming_appointment.book_id));
            localStorage.setItem('getScheduleDate', JSON.stringify(mydate));
            localStorage.setItem('getScheduleFullDate', JSON.stringify(formattedLatestDate));
            localStorage.setItem('getScheduleTime', JSON.stringify(upcoming_appointment.book_time));
        }

    }, [setServiceItems, setGetTime, setDate, upcoming_appointment]);

    useEffect(() => {

        // Load schedule date from local storage when the component mounts
        const storedScheduleDate = localStorage.getItem('getScheduleDate');
        if (storedScheduleDate) {
            setGetFormattedDate(JSON.parse(storedScheduleDate));
        }
    }, [setGetFormattedDate]);


    let formattedSelectedDate;

    const handleDateSelect = (date) => {
        const selectedDateWithOffset = new Date(
            date.getTime() - date.getTimezoneOffset() * 60 * 1000
        );
        console.log(selectedDateWithOffset);
        setDate(selectedDateWithOffset);
        formattedSelectedDate = selectedDateWithOffset.toISOString().split('T')[0];
        setGetFormattedDate(formattedSelectedDate);

        setFormData({
            book_id: getBookId,
            book_time: gTime,
            book_date: formattedSelectedDate,
        });

    };

    const onChangeTime = (e) => {
        setGetTime(e.target.value);
        setFormData({
            book_id: getBookId,
            book_time: e.target.value,
            book_date: getFormattedDate,
        });
    }

    return (
        <Fragment>
            <div className="">
                <div className="col-md-12 banner">
                    <p className="heading px-md-5 px-4">Pick a date & time</p>
                </div>
            </div>
            <section id="contentarea2">
                <div className="container-fluid">
                    <div className="row p-5">
                        <Alert />
                        <div className="col-md-8">
                            <div className="dateandtime card shadow mb-4">
                                <div className="row mb-5">
                                    <div className="col-md-5">
                                        <p className="p-5">
                                            <div className="calendar-container">
                                                <Calendar onChange={(e) => handleDateSelect(e)} value={date} />
                                            </div>
                                            <div className="text-center">
                                                Selected date: {date.toDateString()}
                                            </div>
                                        </p>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="row">
                                            <ul className="nav nav-pills nav-fill">
                                                <li className="nav-item">
                                                    <Link className="nav-link active" aria-current="page" to="#">Active</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="#">Much longer nav link</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="#">Link</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link disabled" aria-disabled="true">Disabled</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-7 d-none d-md-block">
                                        <div className="row">
                                            <div className="col-md-4 text-center">
                                                <div className="btn morningbtn">Morning</div>
                                                <ul className="nav">
                                                    <li ><button type="button" value='10:00 AM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '10:00 AM' ? 'seltimings' : 'timings'}`}>10:00 AM</button></li>
                                                    <li ><button type="button" value='10:15 AM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '10:15 AM' ? 'seltimings' : 'timings'}`}>10:15 AM</button></li>
                                                    <li ><button type="button" value='10:30 AM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '10:30 AM' ? 'seltimings' : 'timings'}`}>10:30 AM</button></li>
                                                    <li ><button type="button" value='10:45 AM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '10:45 AM' ? 'seltimings' : 'timings'}`}>10:45 AM</button></li>
                                                    <li ><button type="button" value='11:00 AM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '11:00 AM' ? 'seltimings' : 'timings'}`}>11:00 AM</button></li>
                                                    <li ><button type="button" value='11:15 AM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '11:15 AM' ? 'seltimings' : 'timings'}`}>11:15 AM</button></li>
                                                    <li ><button type="button" value='11:30 AM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '11:30 AM' ? 'seltimings' : 'timings'}`}>11:30 AM</button></li>
                                                    <li ><button type="button" value='11:45 AM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '11:45 AM' ? 'seltimings' : 'timings'}`}>11:45 AM</button></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 text-center">
                                                <div className="btn morningbtn">Afternoon</div>
                                                <ul className="nav">
                                                    <li ><button type="button" value='12:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '12:00 PM' ? 'seltimings' : 'timings'}`}>12:00 PM</button></li>
                                                    <li ><button type="button" value='12:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '12:15 PM' ? 'seltimings' : 'timings'}`}>12:15 PM</button></li>
                                                    <li ><button type="button" value='12:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '12:30 PM' ? 'seltimings' : 'timings'}`}>12:30 PM</button></li>
                                                    <li ><button type="button" value='12:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '12:45 PM' ? 'seltimings' : 'timings'}`}>12:45 PM</button></li>
                                                    <li ><button type="button" value='01:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '01:00 PM' ? 'seltimings' : 'timings'}`}>01:00 PM</button></li>
                                                    <li ><button type="button" value='01:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '01:15 PM' ? 'seltimings' : 'timings'}`}>01:15 PM</button></li>
                                                    <li ><button type="button" value='01:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '01:30 PM' ? 'seltimings' : 'timings'}`}>01:30 PM</button></li>
                                                    <li ><button type="button" value='01:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '01:45 PM' ? 'seltimings' : 'timings'}`}>01:45 PM</button></li>
                                                    <li ><button type="button" value='02:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '02:00 PM' ? 'seltimings' : 'timings'}`}>02:00 PM</button></li>
                                                    <li ><button type="button" value='02:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '02:15 PM' ? 'seltimings' : 'timings'}`}>02:15 PM</button></li>
                                                    <li ><button type="button" value='02:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '02:30 PM' ? 'seltimings' : 'timings'}`}>02:30 PM</button></li>
                                                    <li ><button type="button" value='02:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '02:45 PM' ? 'seltimings' : 'timings'}`}>02:45 PM</button></li>
                                                    <li ><button type="button" value='03:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '03:00 PM' ? 'seltimings' : 'timings'}`}>03:00 PM</button></li>
                                                    <li ><button type="button" value='03:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '03:15 PM' ? 'seltimings' : 'timings'}`}>03:15 PM</button></li>
                                                    <li ><button type="button" value='03:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '03:30 PM' ? 'seltimings' : 'timings'}`}>03:30 PM</button></li>
                                                    <li ><button type="button" value='03:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '03:45 PM' ? 'seltimings' : 'timings'}`}>03:45 PM</button></li>
                                                    <li ><button type="button" value='04:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '04:00 PM' ? 'seltimings' : 'timings'}`}>04:00 PM</button></li>
                                                    <li ><button type="button" value='04:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '04:15 PM' ? 'seltimings' : 'timings'}`}>04:15 PM</button></li>
                                                    <li ><button type="button" value='04:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '04:30 PM' ? 'seltimings' : 'timings'}`}>04:30 PM</button></li>
                                                    <li ><button type="button" value='04:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '04:45 PM' ? 'seltimings' : 'timings'}`}>04:45 PM</button></li>
                                                    <li ><button type="button" value='05:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '05:00 PM' ? 'seltimings' : 'timings'}`}>05:00 PM</button></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 text-center">
                                                <div className="btn morningbtn">Evening</div>
                                                <ul className="nav">
                                                    <li ><button type="button" value='05:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '05:15 PM' ? 'seltimings' : 'timings'}`}>05:15 PM</button></li>
                                                    <li ><button type="button" value='05:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '05:30 PM' ? 'seltimings' : 'timings'}`}>05:30 PM</button></li>
                                                    <li ><button type="button" value='05:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '05:45 PM' ? 'seltimings' : 'timings'}`}>05:45 PM</button></li>
                                                    <li ><button type="button" value='06:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '06:00 PM' ? 'seltimings' : 'timings'}`}>06:00 PM</button></li>
                                                    <li ><button type="button" value='06:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '06:15 PM' ? 'seltimings' : 'timings'}`}>06:15 PM</button></li>
                                                    <li ><button type="button" value='06:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '06:30 PM' ? 'seltimings' : 'timings'}`}>06:30 PM</button></li>
                                                    <li ><button type="button" value='06:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '06:45 PM' ? 'seltimings' : 'timings'}`}>06:45 PM</button></li>
                                                    <li ><button type="button" value='07:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '07:00 PM' ? 'seltimings' : 'timings'}`}>07:00 PM</button></li>
                                                    <li ><button type="button" value='07:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '07:15 PM' ? 'seltimings' : 'timings'}`}>07:15 PM</button></li>
                                                    <li ><button type="button" value='07:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '07:30 PM' ? 'seltimings' : 'timings'}`}>07:30 PM</button></li>
                                                    <li ><button type="button" value='07:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '07:45 PM' ? 'seltimings' : 'timings'}`}>07:45 PM</button></li>
                                                    <li ><button type="button" value='08:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '08:00 PM' ? 'seltimings' : 'timings'}`}>08:00 PM</button></li>
                                                    <li ><button type="button" value='08:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '08:15 PM' ? 'seltimings' : 'timings'}`}>08:15 PM</button></li>
                                                    <li ><button type="button" value='08:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '08:30 PM' ? 'seltimings' : 'timings'}`}>08:30 PM</button></li>
                                                    <li ><button type="button" value='08:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '08:45 PM' ? 'seltimings' : 'timings'}`}>08:45 PM</button></li>
                                                    <li ><button type="button" value='09:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${gTime === '09:00 PM' ? 'seltimings' : 'timings'}`}>09:00 PM</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="thirdcol card shadow">
                                <h3 className="p-3">Review</h3>
                                <table className="table mb-5">
                                    <tbody>
                                        {loading ? <Spinner /> :
                                            <ServiceItems serviceItems={serviceItems} />
                                        }
                                    </tbody>
                                </table>
                                <div className="row g-0 mb-5 blubox">
                                    <div className="col-md-2 text-end p-2"><img src={img.clock_icon} alt="" /></div>
                                    <div className="col-md-10 px-2"><p>Appointment Time<br />
                                        <span className="fw-bolder">{date.toDateString()} {gTime}</span></p></div>
                                </div>
                                <div className="row g-0">
                                    <div className="col-md-12">
                                        <form className="form" onSubmit={e => onSubmit(e)}>
                                            <input type="hidden" className='form-control form-control-lg' name="book_time" id='book_time' value={book_time} required />
                                            <input type='hidden' className='form-control form-control-lg' name="book_date" id='book_date' value={book_date} required />
                                            <input type='hidden' className='form-control form-control-lg' name="book_id" id='book_id' value={book_id} required />
                                            <input type="submit" className="btn addbtn" value="Update" />
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </Fragment>
    )
}

ReSchedule.propTypes = {
    logout: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    upcoming: PropTypes.object.isRequired,
    updateUpcomingAppointmentById: PropTypes.func.isRequired,
    getUpcomingAppointmentById: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    upcoming: state.upcoming

});

export default connect(mapStateToProps, { updateUpcomingAppointmentById, getUpcomingAppointmentById, logout })(ReSchedule)
