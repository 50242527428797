import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import img from '../../utils/images';

const Footer = props => {
    return (
        <Fragment>
            <section id="footer" className='py-3'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <ul className="nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to="#">Privacy Policy</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="#">Terms of Use</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-8">
                            <p className="text-md-end text-center pt-2">
                                Proudly Designed By:
                                <a href="https://octonion.design" target="_blank" className="desined" rel="noopener noreferrer"
                                >OCTONION
                                    <span
                                    ><img src={img.octonion} alt="Octonion logo" width='10px'
                                        /></span>
                                    DESIGN</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}



export default Footer
