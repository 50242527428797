import axios from 'axios';
import {
    GET_CLIENT_WISE_SERVICES, CLIENT_WISE_SERVICE_ERROR
} from './types';
import { base_url } from './constants';

axios.defaults.baseURL = base_url;

//GET client wise services
export const getClientWiseServices = businessName => async dispatch => {

    try {
        const res = await axios.get(`/api/client_services/${businessName}`);
        dispatch({ type: GET_CLIENT_WISE_SERVICES, payload: res.data })
    } catch (err) {
        dispatch({
            type: CLIENT_WISE_SERVICE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}