import axios from 'axios';
import {
    GET_UPCOMING_APPOINTMENTS, UPCOMING_APPOINTMENTS_ERROR, GET_UPCOMING_APPOINTMENT, UPDATE_UPCOMING_APPOINTMENT
} from './types';
import { setAlert } from './alert';


import { base_url } from './constants';

axios.defaults.baseURL = base_url;
//GET Upcoming Appointments
export const getUpcomingAppointments = () => async dispatch => {
    try {
        const res = await axios.get(`/api/appointments/upcoming`);
        console.log(res.data);
        dispatch({ type: GET_UPCOMING_APPOINTMENTS, payload: res.data });
    } catch (err) {
        dispatch({
            type: UPCOMING_APPOINTMENTS_ERROR,
        });
    }
}



//GET Upcoming Appointment By ID
export const getUpcomingAppointmentById = id => async dispatch => {
    try {
        const res = await axios.get(`/api/appointments/upcoming/${id}`);
        dispatch({ type: GET_UPCOMING_APPOINTMENT, payload: res.data });
    } catch (err) {
        dispatch({
            type: UPCOMING_APPOINTMENTS_ERROR,
        });
    }
}


//UPDATE Upcoming Appointment By ID
export const updateUpcomingAppointmentById = ({ book_time, book_date, book_id }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ book_time, book_date });

    try {
        const res = await axios.post(`/api/appointments/upcoming/update/${book_id}`, body, config);
        dispatch(setAlert('Successfully updated', 'success'));
        dispatch({ type: UPDATE_UPCOMING_APPOINTMENT, payload: res.data });

    } catch (err) {
        dispatch({
            type: UPCOMING_APPOINTMENTS_ERROR,
        });
    }
}





