import {
    GET_PAST_APPOINTMENTS, PAST_APPOINTMENTS_ERROR
} from '../actions/types';


const initialState = { past_appointments: [], past_appointment: null, loading: true, error: {} }

export default function past(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_PAST_APPOINTMENTS:
            return {
                ...state,
                past_appointments: payload,
                loading: false
            }
        case PAST_APPOINTMENTS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }

}