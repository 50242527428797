import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';


const SidebarMenu = () => {
    const [bName, setbName] = useState('');
    const [open, setOpen] = useState(false);


    useEffect(() => {
        const storedBusinessName = localStorage.getItem('businessName');
        if (storedBusinessName) {
            setbName(storedBusinessName);
        }
    }, []);
    return (
        <>
            <div className="col-md-3 d-none d-md-block">
                <div className="sidebar">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><Link to={`/${bName}/dashboard`}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M0 8.88889H7.11111V0H0V8.88889ZM0 16H7.11111V10.6667H0V16ZM8.88889 16H16V7.11111H8.88889V16ZM8.88889 0V5.33333H16V0H8.88889Z" fill="black" />
                        </svg> Home</Link>
                        </li>
                        <li onClick={() => setOpen(!open)} className={open ? 'list-group-item open' : 'list-group-item'}><Link
                            aria-controls="example-collapse-text"
                            aria-expanded={open}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M0 4.8H3.42857V16H0V4.8ZM6.4 0H9.6V16H6.4V0ZM12.8 9.14286H16V16H12.8V9.14286Z" fill="black" />
                            </svg> Appointments</Link>
                            <ul className={open ? 'list-group sidebar-content ps-2   mt-2' : 'list-group sidebar-content ps-2'}>
                                <li className='list-group-item'><Link to={`/${bName}/upcoming-appointments`} >Upcoming Appointments</Link></li>
                                <li className='list-group-item'><Link to={`/${bName}/past-appointments`} >Past Appointments</Link></li>
                                <li className='list-group-item'><Link to={`/${bName}/cancelled-appointments`} >Cancelled Appointments</Link></li>
                            </ul>
                        </li>

                        <li className="list-group-item"><Link to={`/${bName}/account-settings`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M14.1169 9.02977C14.1512 8.77276 14.1683 8.50718 14.1683 8.22446C14.1683 7.95031 14.1512 7.67616 14.1084 7.41915L15.8475 6.06554C16.0017 5.9456 16.0445 5.71429 15.9503 5.54294L14.3054 2.69865C14.2026 2.51017 13.9884 2.4502 13.8 2.51017L11.7524 3.33262C11.3241 3.00707 10.87 2.73292 10.3645 2.52731L10.0561 0.351253C10.0218 0.145641 9.8505 0 9.64489 0H6.35511C6.1495 0 5.98672 0.145641 5.95245 0.351253L5.64404 2.52731C5.13857 2.73292 4.67595 3.01563 4.25616 3.33262L2.20861 2.51017C2.02013 2.44164 1.80595 2.51017 1.70315 2.69865L0.066824 5.54294C-0.0359817 5.72285 -0.00171323 5.9456 0.16963 6.06554L1.90876 7.41915C1.86592 7.67616 1.83166 7.95888 1.83166 8.22446C1.83166 8.49004 1.84879 8.77276 1.89163 9.02977L0.152495 10.3834C-0.00171322 10.5033 -0.0445488 10.7346 0.0496898 10.906L1.69458 13.7503C1.79739 13.9387 2.01157 13.9987 2.20004 13.9387L4.24759 13.1163C4.67595 13.4418 5.13001 13.716 5.63547 13.9216L5.94389 16.0977C5.98672 16.3033 6.1495 16.4489 6.35511 16.4489H9.64489C9.8505 16.4489 10.0218 16.3033 10.0475 16.0977L10.356 13.9216C10.8614 13.716 11.3241 13.4418 11.7438 13.1163L13.7914 13.9387C13.9799 14.0073 14.194 13.9387 14.2969 13.7503L15.9417 10.906C16.0445 10.7175 16.0017 10.5033 15.8389 10.3834L14.1169 9.02977ZM8 11.3086C6.30371 11.3086 4.91583 9.92075 4.91583 8.22446C4.91583 6.52816 6.30371 5.14029 8 5.14029C9.69629 5.14029 11.0842 6.52816 11.0842 8.22446C11.0842 9.92075 9.69629 11.3086 8 11.3086Z" fill="black" />
                        </svg> Account Settings</Link>
                        </li>
                    </ul>
                </div >
            </div >
        </>
    )
}


export default SidebarMenu
