import {
    CANCEL_BOOKING_ERROR, SUCCESS_CANCEL_BOOKING, GET_CANCEL_BOOKINGS
} from '../actions/types';


const initialState = { bookings: [], booking: null, loading: true, error: {} }

export default function booking(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CANCEL_BOOKINGS:
            return {
                ...state,
                bookings: payload,
                loading: false
            }
        case SUCCESS_CANCEL_BOOKING:
            return {
                ...state,
                booking: payload,
                loading: false
            }

        case CANCEL_BOOKING_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }

}