import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SidebarMenu from './SidebarMenu'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import { Navigate, useParams } from 'react-router-dom';
import { getUpcomingAppointments } from '../../actions/upcoming_appointments';

const CustomerDashboard = ({ getUpcomingAppointments, upcoming: { upcoming_appointments, loading } }) => {
    const [upcomingCount, setUpcomingCount] = useState();

    const [bName, setbName] = useState('');


    useEffect(() => {
        const storedBusinessName = localStorage.getItem('businessName');
        if (storedBusinessName) {
            setbName(storedBusinessName);
        }
    }, []);

    useEffect(() => {
        getUpcomingAppointments();
    }, [getUpcomingAppointments]);
    useEffect(() => {
        setUpcomingCount(upcoming_appointments.length);
    }, [loading, upcoming_appointments]);


    return (
        <Fragment>
            <section id="contentarea" className='vh-100'>
                <div className="container">
                    <div className="row">
                        <SidebarMenu />
                        <div className='col-md-9'>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card shadow mb-4">
                                        <Link to={`/${bName}/upcoming-appointments`} className="item-box text-dark">
                                            <span className="font90">{upcomingCount}</span>
                                            <p className='fs-5 mt-4'>Upcoming Appointments</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card shadow mb-4">
                                        <Link to={`/${bName}/past-appointments`} className="item-box text-dark">
                                            <span className="font90">0</span>
                                            <p className='fs-5 mt-4'>Past Appointments</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card shadow mb-4">
                                        <Link to={`/${bName}/cancelled-appointments`} className="item-box text-dark">
                                            <span className="font90">0</span>
                                            <p className='fs-5 mt-4'>Cancelled Appointments</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

CustomerDashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    upcoming: PropTypes.object.isRequired,
    getUpcomingAppointments: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    upcoming: state.upcoming

});
export default connect(mapStateToProps, { getUpcomingAppointments })(CustomerDashboard)
