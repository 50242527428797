import {
    GET_UPCOMING_APPOINTMENTS, UPCOMING_APPOINTMENTS_ERROR, GET_UPCOMING_APPOINTMENT, UPDATE_UPCOMING_APPOINTMENT
} from '../actions/types';


const initialState = { upcoming_appointments: [], upcoming_appointment: null, loading: true, error: {} }

export default function upcoming(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_UPCOMING_APPOINTMENTS:
            return {
                ...state,
                upcoming_appointments: payload,
                loading: false
            }
        case GET_UPCOMING_APPOINTMENT:
            return {
                ...state,
                upcoming_appointment: payload,
                loading: false
            }
        case UPDATE_UPCOMING_APPOINTMENT:
            return {
                ...state,
                upcoming_appointment: [...state.upcoming_appointment, payload],
                loading: false
            }
        case UPCOMING_APPOINTMENTS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }

}