import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Calendar from 'react-calendar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SelectedServices from '../services/SelectedServices';
import img from '../../utils/images'
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
// import DatePicker from 'react-date-picker';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';


const Schedule = ({ isAuthenticated }) => {
    const today = new Date();
    const [scheduleDate, setScheduleDate] = useState(new Date());
    const [formattedSelDate, setFormattedDate] = useState(() => {
        return JSON.parse(localStorage.getItem('scheduleDate'))
    });
    const [time, setTime] = useState(() => {
        return JSON.parse(localStorage.getItem('scheduleTime'))
    });
    const navigate = useNavigate();

    //Decleration -> Storing locally
    const [addToBag, setAddToBag] = useState(() => {
        return JSON.parse(localStorage.getItem('addToBag')) || []
    });

    const [currency, setCurrency] = useState('');

    const [hours, setHours] = useState(() => {
        return JSON.parse(localStorage.getItem('totalHours'));
    });
    const [minute, setMinutes] = useState(() => {
        return JSON.parse(localStorage.getItem('totalMinutes'));
    });
    const [totalBill, setTotalBill] = useState(() => {
        return JSON.parse(localStorage.getItem('totalBill'));
    });


    const endDate = new Date(today);
    endDate.setDate(endDate.getDate() + 6);

    //GET Param - business name
    const { businessName } = useParams();
    const { formattedDate } = useParams();



    let formattedSelectedDate;

    const handleDateSelect = (date) => {
        if (date) {
            const selectedDateWithOffset = new Date(
                date.getTime() - date.getTimezoneOffset() * 60 * 1000
            );
            setScheduleDate(selectedDateWithOffset);

            formattedSelectedDate = selectedDateWithOffset.toISOString().split('T')[0];

            setFormattedDate(formattedSelectedDate ? formattedSelectedDate : formattedDate);
            // Update the URL with the selected date
            navigate(`/${businessName}/availability/${formattedSelectedDate}`);
        } else {
            setScheduleDate(new Date());
        }

    };

    useEffect(() => {
        setCurrency(localStorage.getItem('currencySymbol'));

        setFormattedDate(formattedDate);
        // Update local storage whenever  change
        localStorage.setItem('scheduleDate', JSON.stringify(formattedSelDate));
        localStorage.setItem('scheduleFullDate', JSON.stringify(scheduleDate));
        localStorage.setItem('scheduleTime', JSON.stringify(time));


    }, [formattedDate, scheduleDate, time, formattedSelDate]);

    // use effect for get local storage
    useEffect(() => {
        // Load tasks from local storage when the component mounts
        const storedServices = localStorage.getItem('addToBag');
        if (storedServices) {
            setAddToBag(JSON.parse(storedServices));
        }



        // Load hours from local storage when the component mounts
        const storedTotalHours = localStorage.getItem('totalHours');
        if (storedTotalHours) {
            setHours(JSON.parse(storedTotalHours));
        }


        // Load minutes from local storage when the component mounts
        const storedTotalMinutes = localStorage.getItem('totalMinutes');
        if (storedTotalMinutes) {
            setMinutes(JSON.parse(storedTotalMinutes));
        }


        // Load total bill from local storage when the component mounts
        const storedTotalBilled = localStorage.getItem('totalBill');
        if (storedTotalBilled) {
            setTotalBill(JSON.parse(storedTotalBilled));
        }

        // Load schedule date from local storage when the component mounts
        const storedScheduleDate = localStorage.getItem('scheduleDate');
        if (storedScheduleDate) {
            setFormattedDate(JSON.parse(storedScheduleDate));
        }

        // Load schedule full date from local storage when the component mounts
        const storedScheduleFullDate = localStorage.getItem('scheduleFullDate');
        if (storedScheduleFullDate) {
            setScheduleDate(JSON.parse(storedScheduleFullDate));
        }

        const newDate = new Date(
            scheduleDate.getTime() - scheduleDate.getTimezoneOffset() * 60 * 1000
        );

        setScheduleDate(newDate);

    }, []);

    const onChangeTime = (e) => {
        setTime(e.target.value);
    }
    return (
        <Fragment>
            <div className="banner">
                <div className="container ">
                    <div className="col-md-12">
                        <p className="heading">Pick a date & time</p>
                    </div>
                </div>
            </div>
            <section id="contentarea2">
                <div className="container">
                    <div className="row py-3">
                        <div className="col-md-8">
                            <div className="avialability card  mb-4">
                                <div className="row mb-5">
                                    <div className="col-md-4 col-xl-5">
                                        <p className="p-3">
                                            <div className="calendar-container d-none d-xl-block">
                                                <Calendar onChange={(e) => handleDateSelect(e)} value={scheduleDate} />
                                            </div>
                                            <div className="d-xxl-none text-center">
                                                <Calendar onChange={(e) => handleDateSelect(e)} value={scheduleDate} minDate={today}
                                                    maxDate={endDate} />
                                            </div>
                                            <div className="text-center d-none d-xl-block">
                                                Selected date: {scheduleDate.toDateString()}
                                            </div>
                                        </p>
                                    </div>
                                    <div className="col-md-7 d-md-none mt-3">
                                        <div className="row">
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <ul className="nav nav-pills mb-3 mobileTabs" id="pills-tab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link  active" id="pills-morning-tab" data-bs-toggle="pill" data-bs-target="#pills-morning" type="button" role="tab" aria-controls="pills-morning" aria-selected="true">Morning</button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-afernoon-tab" data-bs-toggle="pill" data-bs-target="#pills-afernoon" type="button" role="tab" aria-controls="pills-afernoon" aria-selected="false">Afternoon</button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-evening-tab" data-bs-toggle="pill" data-bs-target="#pills-evening" type="button" role="tab" aria-controls="pills-evening" aria-selected="false">Evening</button>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content" id="pills-tabContent">
                                                        <div className="tab-pane fade show active" id="pills-morning" role="tabpanel" aria-labelledby="pills-morning-tab" tabindex="0">
                                                            <ul className="nav-vertical">
                                                                <li className=""><button type="button" value='10:00 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '10:00 AM' ? 'seltimings' : 'timings'}`}>10:00 AM</button></li>
                                                                {/* <li className=""><button type="button" value='10:15 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '10:15 AM' ? 'seltimings' : 'timings'}`}>10:15 AM</button></li> */}
                                                                <li className=""><button type="button" value='10:30 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '10:30 AM' ? 'seltimings' : 'timings'}`}>10:30 AM</button></li>
                                                                {/* <li className=""><button type="button" value='10:45 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '10:45 AM' ? 'seltimings' : 'timings'}`}>10:45 AM</button></li> */}
                                                                <li className=""><button type="button" value='11:00 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '11:00 AM' ? 'seltimings' : 'timings'}`}>11:00 AM</button></li>
                                                                {/* <li className=""><button type="button" value='11:15 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '11:15 AM' ? 'seltimings' : 'timings'}`}>11:15 AM</button></li> */}
                                                                <li className=""><button type="button" value='11:30 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '11:30 AM' ? 'seltimings' : 'timings'}`}>11:30 AM</button></li>
                                                                {/* <li className=""><button type="button" value='11:45 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '11:45 AM' ? 'seltimings' : 'timings'}`}>11:45 AM</button></li> */}
                                                            </ul>
                                                        </div>
                                                        <div className="tab-pane fade" id="pills-afernoon" role="tabpanel" aria-labelledby="pills-afernoon-tab" tabindex="0">
                                                            <ul className="nav-vertical">
                                                                <li className=""><button type="button" value='12:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '12:00 PM' ? 'seltimings' : 'timings'}`}>12:00 PM</button></li>
                                                                {/* <li className=""><button type="button" value='12:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '12:15 PM' ? 'seltimings' : 'timings'}`}>12:15 PM</button></li> */}
                                                                <li className=""><button type="button" value='12:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '12:30 PM' ? 'seltimings' : 'timings'}`}>12:30 PM</button></li>
                                                                {/* <li className=""><button type="button" value='12:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '12:45 PM' ? 'seltimings' : 'timings'}`}>12:45 PM</button></li> */}
                                                                <li className=""><button type="button" value='01:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '01:00 PM' ? 'seltimings' : 'timings'}`}>01:00 PM</button></li>
                                                                {/* <li className=""><button type="button" value='01:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '01:15 PM' ? 'seltimings' : 'timings'}`}>01:15 PM</button></li> */}
                                                                <li className=""><button type="button" value='01:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '01:30 PM' ? 'seltimings' : 'timings'}`}>01:30 PM</button></li>
                                                                {/* <li className=""><button type="button" value='01:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '01:45 PM' ? 'seltimings' : 'timings'}`}>01:45 PM</button></li> */}
                                                                <li className=""><button type="button" value='02:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '02:00 PM' ? 'seltimings' : 'timings'}`}>02:00 PM</button></li>
                                                                {/* <li className=""><button type="button" value='02:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '02:15 PM' ? 'seltimings' : 'timings'}`}>02:15 PM</button></li> */}
                                                                <li className=""><button type="button" value='02:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '02:30 PM' ? 'seltimings' : 'timings'}`}>02:30 PM</button></li>
                                                                {/* <li className=""><button type="button" value='02:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '02:45 PM' ? 'seltimings' : 'timings'}`}>02:45 PM</button></li> */}
                                                                <li className=""><button type="button" value='03:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '03:00 PM' ? 'seltimings' : 'timings'}`}>03:00 PM</button></li>
                                                                {/* <li className=""><button type="button" value='03:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '03:15 PM' ? 'seltimings' : 'timings'}`}>03:15 PM</button></li> */}
                                                                <li className=""><button type="button" value='03:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '03:30 PM' ? 'seltimings' : 'timings'}`}>03:30 PM</button></li>
                                                                {/* <li className=""><button type="button" value='03:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '03:45 PM' ? 'seltimings' : 'timings'}`}>03:45 PM</button></li> */}
                                                                <li className=""><button type="button" value='04:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '04:00 PM' ? 'seltimings' : 'timings'}`}>04:00 PM</button></li>
                                                                {/* <li className=""><button type="button" value='04:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '04:15 PM' ? 'seltimings' : 'timings'}`}>04:15 PM</button></li> */}
                                                                <li className=""><button type="button" value='04:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '04:30 PM' ? 'seltimings' : 'timings'}`}>04:30 PM</button></li>
                                                                {/* <li className=""><button type="button" value='04:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '04:45 PM' ? 'seltimings' : 'timings'}`}>04:45 PM</button></li> */}
                                                            </ul>
                                                        </div>
                                                        <div className="tab-pane fade" id="pills-evening" role="tabpanel" aria-labelledby="pills-evening-tab" tabindex="0">
                                                            <ul className="nav-vertical">
                                                                <li className=""><button type="button" value='05:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '05:00 PM' ? 'seltimings' : 'timings'}`}>05:00 PM</button></li>
                                                                {/* <li className=""><button type="button" value='05:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '05:15 PM' ? 'seltimings' : 'timings'}`}>05:15 PM</button></li> */}
                                                                <li className=""><button type="button" value='05:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '05:30 PM' ? 'seltimings' : 'timings'}`}>05:30 PM</button></li>
                                                                {/* <li className=""><button type="button" value='05:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '05:45 PM' ? 'seltimings' : 'timings'}`}>05:45 PM</button></li> */}
                                                                <li className=""><button type="button" value='06:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '06:00 PM' ? 'seltimings' : 'timings'}`}>06:00 PM</button></li>
                                                                {/* <li className=""><button type="button" value='06:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '06:15 PM' ? 'seltimings' : 'timings'}`}>06:15 PM</button></li> */}
                                                                <li className=""><button type="button" value='06:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '06:30 PM' ? 'seltimings' : 'timings'}`}>06:30 PM</button></li>
                                                                {/* <li className=""><button type="button" value='06:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '06:45 PM' ? 'seltimings' : 'timings'}`}>06:45 PM</button></li> */}
                                                                <li className=""><button type="button" value='07:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '07:00 PM' ? 'seltimings' : 'timings'}`}>07:00 PM</button></li>
                                                                {/* <li className=""><button type="button" value='07:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '07:15 PM' ? 'seltimings' : 'timings'}`}>07:15 PM</button></li> */}
                                                                <li className=""><button type="button" value='07:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '07:30 PM' ? 'seltimings' : 'timings'}`}>07:30 PM</button></li>
                                                                {/* <li className=""><button type="button" value='07:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '07:45 PM' ? 'seltimings' : 'timings'}`}>07:45 PM</button></li> */}
                                                                <li className=""><button type="button" value='08:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '08:00 PM' ? 'seltimings' : 'timings'}`}>08:00 PM</button></li>
                                                                {/* <li className=""><button type="button" value='08:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '08:15 PM' ? 'seltimings' : 'timings'}`}>08:15 PM</button></li> */}
                                                                <li className=""><button type="button" value='08:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '08:30 PM' ? 'seltimings' : 'timings'}`}>08:30 PM</button></li>
                                                                {/* <li className=""><button type="button" value='08:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '08:45 PM' ? 'seltimings' : 'timings'}`}>08:45 PM</button></li> */}
                                                                <li className=""><button type="button" value='09:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '09:00 PM' ? 'seltimings' : 'timings'}`}>09:00 PM</button></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-8 col-xl-7 d-none d-md-block">
                                        <div className="d-flex">
                                            <div className="col-md-4 text-center">
                                                <div className="btn morningbtn">Morning</div>
                                                <ul className="nav">
                                                    <li className=""><button type="button" value='10:00 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '10:00 AM' ? 'seltimings' : 'timings'}`}>10:00 AM</button></li>
                                                    {/* <li className=""><button type="button" value='10:15 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '10:15 AM' ? 'seltimings' : 'timings'}`}>10:15 AM</button></li> */}
                                                    <li className=""><button type="button" value='10:30 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '10:30 AM' ? 'seltimings' : 'timings'}`}>10:30 AM</button></li>
                                                    {/* <li className=""><button type="button" value='10:45 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '10:45 AM' ? 'seltimings' : 'timings'}`}>10:45 AM</button></li> */}
                                                    <li className=""><button type="button" value='11:00 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '11:00 AM' ? 'seltimings' : 'timings'}`}>11:00 AM</button></li>
                                                    {/* <li className=""><button type="button" value='11:15 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '11:15 AM' ? 'seltimings' : 'timings'}`}>11:15 AM</button></li> */}
                                                    <li className=""><button type="button" value='11:30 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '11:30 AM' ? 'seltimings' : 'timings'}`}>11:30 AM</button></li>
                                                    {/* <li className=""><button type="button" value='11:45 AM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '11:45 AM' ? 'seltimings' : 'timings'}`}>11:45 AM</button></li> */}
                                                </ul>
                                            </div>
                                            <div className="col-md-4 text-center">
                                                <div className="btn morningbtn">Afternoon</div>
                                                <ul className="nav">
                                                    <li className=""><button type="button" value='12:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '12:00 PM' ? 'seltimings' : 'timings'}`}>12:00 PM</button></li>
                                                    {/* <li className=""><button type="button" value='12:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '12:15 PM' ? 'seltimings' : 'timings'}`}>12:15 PM</button></li> */}
                                                    <li className=""><button type="button" value='12:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '12:30 PM' ? 'seltimings' : 'timings'}`}>12:30 PM</button></li>
                                                    {/* <li className=""><button type="button" value='12:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '12:45 PM' ? 'seltimings' : 'timings'}`}>12:45 PM</button></li> */}
                                                    <li className=""><button type="button" value='01:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '01:00 PM' ? 'seltimings' : 'timings'}`}>01:00 PM</button></li>
                                                    {/* <li className=""><button type="button" value='01:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '01:15 PM' ? 'seltimings' : 'timings'}`}>01:15 PM</button></li> */}
                                                    <li className=""><button type="button" value='01:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '01:30 PM' ? 'seltimings' : 'timings'}`}>01:30 PM</button></li>
                                                    {/* <li className=""><button type="button" value='01:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '01:45 PM' ? 'seltimings' : 'timings'}`}>01:45 PM</button></li> */}
                                                    <li className=""><button type="button" value='02:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '02:00 PM' ? 'seltimings' : 'timings'}`}>02:00 PM</button></li>
                                                    {/* <li className=""><button type="button" value='02:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '02:15 PM' ? 'seltimings' : 'timings'}`}>02:15 PM</button></li> */}
                                                    <li className=""><button type="button" value='02:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '02:30 PM' ? 'seltimings' : 'timings'}`}>02:30 PM</button></li>
                                                    {/* <li className=""><button type="button" value='02:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '02:45 PM' ? 'seltimings' : 'timings'}`}>02:45 PM</button></li> */}
                                                    <li className=""><button type="button" value='03:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '03:00 PM' ? 'seltimings' : 'timings'}`}>03:00 PM</button></li>
                                                    {/* <li className=""><button type="button" value='03:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '03:15 PM' ? 'seltimings' : 'timings'}`}>03:15 PM</button></li> */}
                                                    <li className=""><button type="button" value='03:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '03:30 PM' ? 'seltimings' : 'timings'}`}>03:30 PM</button></li>
                                                    {/* <li className=""><button type="button" value='03:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '03:45 PM' ? 'seltimings' : 'timings'}`}>03:45 PM</button></li> */}
                                                    <li className=""><button type="button" value='04:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '04:00 PM' ? 'seltimings' : 'timings'}`}>04:00 PM</button></li>
                                                    {/* <li className=""><button type="button" value='04:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '04:15 PM' ? 'seltimings' : 'timings'}`}>04:15 PM</button></li> */}
                                                    <li className=""><button type="button" value='04:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '04:30 PM' ? 'seltimings' : 'timings'}`}>04:30 PM</button></li>
                                                    {/* <li className=""><button type="button" value='04:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '04:45 PM' ? 'seltimings' : 'timings'}`}>04:45 PM</button></li> */}
                                                </ul>
                                            </div>
                                            <div className="col-md-4 text-center">
                                                <div className="btn morningbtn">Evening</div>
                                                <ul className="nav">
                                                    <li className=""><button type="button" value='05:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '05:00 PM' ? 'seltimings' : 'timings'}`}>05:00 PM</button></li>
                                                    {/* <li className=""><button type="button" value='05:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '05:15 PM' ? 'seltimings' : 'timings'}`}>05:15 PM</button></li> */}
                                                    <li className=""><button type="button" value='05:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '05:30 PM' ? 'seltimings' : 'timings'}`}>05:30 PM</button></li>
                                                    {/* <li className=""><button type="button" value='05:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '05:45 PM' ? 'seltimings' : 'timings'}`}>05:45 PM</button></li> */}
                                                    <li className=""><button type="button" value='06:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '06:00 PM' ? 'seltimings' : 'timings'}`}>06:00 PM</button></li>
                                                    {/* <li className=""><button type="button" value='06:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '06:15 PM' ? 'seltimings' : 'timings'}`}>06:15 PM</button></li> */}
                                                    <li className=""><button type="button" value='06:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '06:30 PM' ? 'seltimings' : 'timings'}`}>06:30 PM</button></li>
                                                    {/* <li className=""><button type="button" value='06:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '06:45 PM' ? 'seltimings' : 'timings'}`}>06:45 PM</button></li> */}
                                                    <li className=""><button type="button" value='07:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '07:00 PM' ? 'seltimings' : 'timings'}`}>07:00 PM</button></li>
                                                    {/* <li className=""><button type="button" value='07:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '07:15 PM' ? 'seltimings' : 'timings'}`}>07:15 PM</button></li> */}
                                                    <li className=""><button type="button" value='07:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '07:30 PM' ? 'seltimings' : 'timings'}`}>07:30 PM</button></li>
                                                    {/* <li className=""><button type="button" value='07:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '07:45 PM' ? 'seltimings' : 'timings'}`}>07:45 PM</button></li> */}
                                                    <li className=""><button type="button" value='08:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '08:00 PM' ? 'seltimings' : 'timings'}`}>08:00 PM</button></li>
                                                    {/* <li className=""><button type="button" value='08:15 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '08:15 PM' ? 'seltimings' : 'timings'}`}>08:15 PM</button></li> */}
                                                    <li className=""><button type="button" value='08:30 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '08:30 PM' ? 'seltimings' : 'timings'}`}>08:30 PM</button></li>
                                                    {/* <li className=""><button type="button" value='08:45 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '08:45 PM' ? 'seltimings' : 'timings'}`}>08:45 PM</button></li> */}
                                                    <li className=""><button type="button" value='09:00 PM' onClick={(e) => onChangeTime(e)} className={`btn ${time === '09:00 PM' ? 'seltimings' : 'timings'}`}>09:00 PM</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-none d-md-block">
                            <div className="thirdcol card shadow">
                                <h3 className="p-3">Review</h3>
                                <SelectedServices services={addToBag} totalHours={hours} totalMinutes={minute} totalBill={totalBill} currency={currency} />
                                <div className="row g-0 mb-3 blubox">
                                    <div className="col-md-2 text-end p-2"><img src={img.clock_icon} alt="" /></div>
                                    <div className="col-md-10 px-2"><p>Appointment Time<br />
                                        <span className="fw-bolder">{scheduleDate.toDateString()} {time}</span></p></div>
                                </div>
                                <div className="row g-0 p-2">

                                    {time ?
                                        <Link to={`${isAuthenticated ? `/${businessName}/checkout` : `/${businessName}/login`}`} className="btn addbtn py-3" >Add to Bag</Link> : <button type="button" className="btn addbtn" disabled>Add to Bag</button>
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="fixed-bottom d-md-none" id="review-section">
                    <div className="blubox">
                        <div className="d-flex align-items-center px-2"><img src={img.clock_icon} alt="clock" /> <p className='ps-3 py-2'>Appointment Time<br />
                            <span className="fw-bolder">{scheduleDate.toDateString()} {time}</span></p></div>
                    </div>
                    <div className="bg-dark p-3 text-light">
                        <div className="d-flex justify-content-around align-items-center">
                            <div className="p-2"><span className="fw-bold">{`${addToBag.length > 0 ? addToBag.length : ''}`}</span> <span className="text-secondary">{`${addToBag.length > 0 ? "Services" : ''}`}</span> </div>
                            <div className="p-2"> <span className='fw-bold'>{hours ? `${Math.floor(hours / 60) + 'hr : ' + hours % 60}Mins` : ''}</span> </div>
                            <div className='p-2 fw-bold'>{`${addToBag.length > 0 ? '$' + totalBill : ''}`}</div>
                            {time ?
                                <Link to={`${isAuthenticated ? `/${businessName}/checkout` : `/${businessName}/login`}`} className="btn addbtn text-light border border-light" >Add to Bag</Link> : <button type="button" className="btn addbtn" disabled>Add to Bag</button>
                            }                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

Schedule.propTypes = {
    logout: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Schedule)
