import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SidebarMenu from '../SidebarMenu'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from '../../layouts/Spinner';

import { getUpcomingAppointmentById } from '../../../actions/upcoming_appointments';
import ServiceItems from './ServiceItems';

const Details = ({ getUpcomingAppointmentById, upcoming: { upcoming_appointment, loading } }) => {

    const [serviceItems, setServiceItems] = useState([]);
    const [bookId, setBookId] = useState()

    const { id } = useParams();
    useEffect(() => {

        getUpcomingAppointmentById(id);
    }, [getUpcomingAppointmentById, id]);


    useEffect(() => {
        if (upcoming_appointment) {
            const bookItems = JSON.parse(upcoming_appointment.book_items);
            setBookId(upcoming_appointment.id);
            if (bookItems)
                setServiceItems(bookItems);
        }

    }, [setServiceItems, upcoming_appointment])

    return (
        <Fragment>
            <section id="contentarea4" className='vh-100'>
                <div className="container">
                    <div className="row">
                        <SidebarMenu />
                        <div className="col-md-9">
                            <div className="card shadow">
                                <h3 className="fw-bolder p-md-4 pt-3 mb-4">Your upcoming Appointment with Order ID : {bookId}</h3>
                                <hr className='mb-0 mb-md-4 d-none d-md-block' />
                                <div className="d-none d-md-block">
                                    <table className="table mb-5">
                                        <thead>
                                            <tr>
                                                <th scope="row" className="ps-4">Service</th>
                                                <th>Time</th>
                                                <th>Service Charges</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? <Spinner /> :
                                                <ServiceItems serviceItems={serviceItems} />
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='card border-start border-end border-top d-md-none'>
                                    {loading ? <Spinner /> :
                                        serviceItems.map((item, index) => (
                                            <div className="d-flex justify-content-between p-3 border-bottom" key={index}>
                                                <div className='fw-bold'>{item.name}</div>
                                                <div>{`${Math.floor(item.duration / 60)} H : ${item.duration % 60} Min `}</div>
                                                <div >${item.price}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

Details.propTypes = {
    auth: PropTypes.object.isRequired,
    upcoming: PropTypes.object.isRequired,
    getUpcomingAppointmentById: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    upcoming: state.upcoming

});

export default connect(mapStateToProps, { getUpcomingAppointmentById })(Details)
