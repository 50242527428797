import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import Alert from '../layouts/Alert';
import { useParams } from 'react-router-dom';
import { paymentSuccess } from '../../actions/payments';

const OrderSuccess = ({ auth: { isAuthenticated }, paymentSuccess, payment: { payment, loading } }) => {

    // const { businessName } = useParams();
    const { sessionid } = useParams();
    console.log(sessionid);
    useEffect(() => {
        paymentSuccess(sessionid);
    }, [paymentSuccess, sessionid]);
    console.log(payment);
    // if (!isAuthenticated) {
    //     return <Navigate to={`/${businessName}/login`} />;
    // }
    return (
        <Fragment>
            <div className="row mb-5">
                <div className="col-md-12 banner">
                    <p className="heading px-5">Thanks! you’re all booked.</p>
                </div>
            </div>
            <div className='my-5 container'>
                <Alert />
            </div>
        </Fragment>
    )
}

OrderSuccess.propTypes = {
    auth: PropTypes.object.isRequired,
    paymentSuccess: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    payment: state.payment
});

export default connect(mapStateToProps, { paymentSuccess })(OrderSuccess)



