import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SidebarMenu from '../SidebarMenu'
import { connect } from 'react-redux';
import {  Link } from 'react-router-dom';


const Settings = ({auth: { user, loading } }) => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobNumber: ''
    });

    const { firstName, lastName, email, mobNumber } = formData;

    useEffect(() => {
        setFormData({
            firstName: loading || user.firstName === null ? '' : user.firstName,
            lastName: loading || user.lastName === null ? '' : user.lastName,
            email: loading || user.email === null ? '' : user.email,
            mobNumber: loading || user.mobNumber === null ? '' : user.mobNumber
        });
    }, [loading, user]);

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = e => {
        e.preventDefault();

    }


   
    return (
        <Fragment>
            <section id="contentarea4" className='vh-100'>
                <div className="container">
                    <div className="row">
                        <SidebarMenu />
                        <div className="col-md-9">
                            <div className="card shadow">
                                <h3 className="fw-bolder p-3 pt-4">Account Settings</h3>
                                <hr />
                                <h5 className="px-3 mt-3">Basic Details</h5>
                                <form method="POST" className="px-3" onSubmit={e => onSubmit(e)}>
                                    <div className="row my-3">
                                        <div className="col-6">
                                            <label className="label">First name <sup className="text-danger">*</sup></label>
                                            <input
                                                className="form-control mt-2"
                                                type="text"
                                                placeholder="First name"
                                                name="firstName"
                                                value={firstName} onChange={e => onChange(e)}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label className="label">Last name <sup className="text-danger">*</sup></label>
                                            <input
                                                className="form-control mt-2"
                                                type="text"
                                                placeholder="Last name"
                                                name="lastName"
                                                value={lastName} onChange={e => onChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-12">
                                            <label className="label">Email ID <sup className="text-danger">*</sup></label>
                                            <input
                                                className="form-control mt-2"
                                                type="text"
                                                placeholder="Email ID"
                                                name="email"
                                                value={email} onChange={e => onChange(e)}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-12">
                                            <label className="label">Phone <sup className="text-danger">*</sup></label>
                                            <input
                                                className="form-control mt-2"
                                                placeholder="Phone Number"
                                                type="text"
                                                name="mobNumber"
                                                value={mobNumber} onChange={e => onChange(e)}
                                            />
                                        </div>
                                    </div>
                                </form>
                                <hr />
                                <div className="row p-4 mb-4">
                                    <div className="col-6">
                                        <h5>Password</h5>
                                    </div>
                                    <div className="col-6">
                                        <h5><Link href="#" className="blulink">Change Password</Link></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </Fragment >
    )
}

Settings.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,

});

export default connect(mapStateToProps)(Settings)
