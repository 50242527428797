import React, { Fragment } from 'react'

const SelectedServices = ({ services, totalHours, totalBill, currency }) => {
    return (
        <Fragment>
            <div className="row g-0">
                <div className="col-md-6 col-6 px-3 fw-bold">Name</div>
                <div className="col-md-3 col-3 text-center fw-bold">Time</div>
                <div className="col-md-3 col-3 text-center fw-bold">Cost</div>
            </div>
            <hr />
            {services.map((localService, index) => (
                <div className="row g-0 mb-3" key={index}>
                    <div className="col-md-6 col-6 px-3">{localService.name}</div>
                    <div className="col-md-3 col-3 text-center">{`${Math.floor(localService.duration / 60)} H : ${localService.duration % 60} Min `}</div>
                    <div className="col-md-3 col-3 text-center">{localService.price}</div>
                </div>
            ))}
            <hr />
            <div className="row g-0 mb-5">
                <div className="col-md-6 px-3 fw-bolder">Total</div>
                <div className="col-md-3 text-center fw-bolder">{totalHours ? `${Math.floor(totalHours / 60) + 'H : ' + totalHours % 60}Min` : 0}</div>
                <div className="col-md-3 text-center fw-bolder">{currency} {totalBill}</div>
            </div>
        </Fragment>
    )
}

export default SelectedServices
