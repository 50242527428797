import React, { Fragment } from 'react'

const ServiceItems = ({ serviceItems }) => {
    return (
        <Fragment>
            {serviceItems.map((item, index) => (<tr key={index}>
                <td className="ps-4">{item.name}</td>
                <td>{`${Math.floor(item.duration / 60)} H : ${item.duration % 60} Min `}</td>
                <td>${item.price}</td>
            </tr>
            ))}
        </Fragment>
    )
}

export default ServiceItems
