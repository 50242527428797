import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ path,element, auth: { isAuthenticated, loading } }) => {

    const [bName, setbName] = useState('');
   console.log(bName);
    useEffect(() => {
        const storedBusinessName = localStorage.getItem('businessName');
        if (storedBusinessName) {
            setbName(storedBusinessName);
        }
    }, []);
    return !loading && ( isAuthenticated ? <Routes><Route path={path} element={element} /></Routes> : <Navigate to={`/${bName}/login`} />);

}

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
