import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { Link } from 'react-router-dom';
import ReviewServices from './ReviewServices';
import { createOrder } from '../../actions/payments';
import Alert from '../layouts/Alert';
import ReviewServicesInMobile from './ReviewServicesInMobile';



const Checkout = ({ auth: { user }, createOrder }) => {
    //Decleration -> Storing locally
    const [addToBag, setAddToBag] = useState(() => {
        return JSON.parse(localStorage.getItem('addToBag')) || []
    });
    const [formattedSelDate, setFormattedFullDate] = useState(() => {
        return JSON.parse(localStorage.getItem('scheduleDate'))
    });
    const [time, setTime] = useState(() => {
        return JSON.parse(localStorage.getItem('scheduleTime'))
    });

    const [currency, setCurrency] = useState('');



    useEffect(() => {
        setCurrency(localStorage.getItem('currencySymbol'));

        const storedServices = localStorage.getItem('addToBag');
        if (storedServices) {
            setAddToBag(JSON.parse(storedServices));
        }
        // Load schedule date from local storage when the component mounts
        const storedScheduleTime = localStorage.getItem('scheduleTime');
        if (storedScheduleTime) {
            setTime(JSON.parse(storedScheduleTime));
        }

        // Load schedule full date from local storage when the component mounts
        const storedScheduleFullDate = localStorage.getItem('scheduleFullDate');
        if (storedScheduleFullDate) {
            const date = new Date(JSON.parse(storedScheduleFullDate));
            const options = { year: 'numeric', day: '2-digit', month: '2-digit' };
            const dt = date.toLocaleDateString('en-US', options);
            let d = dt.split("/");
            var dat = d[2] + '-' + d[0] + '-' + d[1];
            setFormattedFullDate(dat);
        }

    }, [setAddToBag, setTime]);


    const paymentHandler = async (e) => {
        e.preventDefault();
        const clientID = user && user.clientID;
        const email = user && user.email;
        const mob = user && user.mobNumber;
        const amount = localStorage.getItem('totalBill');
        const currency = user && user.currency;
        const bookItems = JSON.stringify(addToBag);
        const book_time = time;
        const book_date = formattedSelDate;
        const account = user && user.stripe_id;
        try {
            await createOrder({ clientID, email, mob, amount, currency, bookItems, book_time, book_date, account });
        } catch (error) {

        }
    };

    return (
        <Fragment>
            <section className="banner">
                <div className="container">
                    <div className="col-md-12 ">
                        <p className="heading">Finish & Book</p>
                    </div>
                </div>
            </section>
            <section id="contentarea">
                <div className='my-md-5 container'>
                    <Alert />
                    <div className='thirdcol'>
                        <div className="mb-5 card shadow d-none d-md-block">
                            <h3 className="p-3">Your Appointment Details</h3>
                            <hr className='mt-0' />
                            <ReviewServices currency={currency} />
                        </div>
                        <div className="d-md-none">
                            <h3 className="p-3">Your Appointment Details</h3>
                            <div className="card shadow d-md-none">
                                <ReviewServicesInMobile currency={currency} />
                            </div>
                        </div>

                        <div className="d-flex justify-content-end">
                            <div className="mt-md-2 mt-3">
                                <Link to='#!' onClick={paymentHandler} className="btn addbtn px-5 py-3 me-0">Checkout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

Checkout.propTypes = {
    auth: PropTypes.object.isRequired,
    createOrder: PropTypes.func.isRequired,

}
const mapStateToProps = state => ({
    auth: state.auth,

});
export default connect(mapStateToProps, { createOrder, logout })(Checkout)
