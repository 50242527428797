import React, { Fragment, useEffect, useContext, useState } from 'react'
import { FaTrashCan } from "react-icons/fa6";
import ValueContext from '../layouts/ValueContext';

const ReviewServices = ({ currency }) => {
    const { refreshValue } = useContext(ValueContext);

    //Decleration -> Storing locally
    const [addToBag, setAddToBag] = useState(() => {
        return JSON.parse(localStorage.getItem('addToBag')) || []
    });
    const [formattedSelDate, setFormattedFullDate] = useState(() => {
        return JSON.parse(localStorage.getItem('scheduleDate'))
    });
    const [time, setTime] = useState(() => {
        return JSON.parse(localStorage.getItem('scheduleTime'))
    });
    const [isChecked, setIsChecked] = useState(() => {
        return JSON.parse(localStorage.getItem('checkBoxStatus')) || []
    });
    useEffect(() => {
        // Update local storage whenever  change
        localStorage.setItem('addToBag', JSON.stringify(addToBag));

    }, [addToBag]);
    const [totalBill, setTotalBill] = useState(() => {
        return JSON.parse(localStorage.getItem('totalBill'));
    });
    useEffect(() => {
        const storedServices = localStorage.getItem('addToBag');
        if (storedServices) {
            setAddToBag(JSON.parse(storedServices));
        }
        // Load schedule date from local storage when the component mounts
        const storedScheduleTime = localStorage.getItem('scheduleTime');
        if (storedScheduleTime) {
            setTime(JSON.parse(storedScheduleTime));
        }

        // Load schedule full date from local storage when the component mounts
        const storedScheduleFullDate = localStorage.getItem('scheduleFullDate');
        if (storedScheduleFullDate) {
            const date = new Date(JSON.parse(storedScheduleFullDate));
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            setFormattedFullDate(date.toLocaleDateString('en-US', options));
        }

        const storedCheckboxState = localStorage.getItem('checkBoxStatus');
        if (storedCheckboxState) {
            setIsChecked(JSON.parse(storedCheckboxState));
        }
        // Load total bill from local storage when the component mounts
        const storedTotalBilled = localStorage.getItem('totalBill');
        if (storedTotalBilled) {
            setTotalBill(JSON.parse(storedTotalBilled));
        }
    }, []);

    const deleteItem = (index) => {

        //LOCAL CHECK BOX REMOVE
        const updatedCheckbox = [...isChecked];
        updatedCheckbox.splice(index, 1);
        localStorage.setItem('checkBoxStatus', JSON.stringify(updatedCheckbox));
        setIsChecked(updatedCheckbox);

        //LOCAL ITEM REMOVE
        const updatedService = [...addToBag];
        console.log(updatedService[index].price);
        const finalBill = totalBill - updatedService[index].price;
        localStorage.setItem('totalBill', JSON.stringify(finalBill));
        setTotalBill(finalBill);
        updatedService.splice(index, 1);
        localStorage.setItem('addToBag', JSON.stringify(updatedService));
        setAddToBag(updatedService);
        refreshValue((addToBag.length) - 1);
    }
    return (
        <Fragment>
            <div className='p-3'>
                {addToBag.length > 0 ?
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Service</th>
                                <th scope="col">Time</th>
                                <th scope="col">Service Charges</th>
                                <th scope="col">Appointment Time</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {addToBag.map((localService, index) => (
                                <tr key={index}>
                                    <td>{localService.name}</td>
                                    <td>{`${Math.floor(localService.duration / 60)} H : ${localService.duration % 60} Min `}</td>
                                    <td>{currency}{localService.price}</td>
                                    <td>{formattedSelDate} {time}</td>
                                    <td onClick={() => deleteItem(index)}><span className='text-danger'><FaTrashCan /></span></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    : <h4 className='text-center p-4 w-100'>No Appointments </h4>

                }
            </div>
        </Fragment>
    )
}

export default ReviewServices
