import axios from 'axios';
import { setAlert } from './alert';
import {
    ADD_ORDER, ORDER_ERROR, ORDER_SUCCESS, ORDER_FAILURE
} from './types';
// import { Navigate, useParams } from 'react-router-dom'; ORDER_SUCCESS

import { base_url } from './constants';

axios.defaults.baseURL = base_url;


// export const createOrder = ({ clientID, email, mob, amount, currency, bookItems, book_time, book_date, razor_account }) => async dispatch => {
//     // const { businessName } = useParams();
//     const config = {
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     }
//     const body = JSON.stringify({ clientID, email, mob, amount, currency, bookItems, book_time, book_date, razor_account });


//     try {
//         const response = await axios.post('api/payment/order', body, config);
//         dispatch({
//             type: ADD_ORDER,
//             payload: response.data
//         });

//         const { data } = response;
//         const options = {
//             key: process.env.REACT_APP_RAZOR_PAY_KEY_ID,
//             name: "BOOQY",
//             description: "",
//             order_id: data.id,
//             prefill: {
//                 email: email,
//                 contact: mob,
//             },
//             handler: async (response) => {
//                 const orderId = data.id;
//                 const razorpayPaymentId = response.razorpay_payment_id;
//                 const signature = response.razorpay_signature;
//                 const res = JSON.stringify({ orderId, razorpayPaymentId, signature, email });
//                 try {
//                     const captureResponse = await axios.post(`api/payment/verify/`, res, config);
//                     dispatch({
//                         type: ORDER_SUCCESS,
//                         payload: captureResponse.data
//                     });
//                     dispatch(setAlert(`Payment Success, An email is sent to ${email}  with the details below with order id ${orderId}.`, 'success'));
//                 } catch (err) {
//                     console.log(err.response.data);
//                 }
//             },
//             theme: {
//                 color: "#686CFD",
//             },
//         };
//         const rzp1 = new window.Razorpay(options);
//         rzp1.on('payment.failed', function (response) {
//             alert(response.error.code);
//             alert(response.error.description);
//             alert(response.error.source);
//             alert(response.error.step);
//             alert(response.error.reason);
//             alert(response.error.metadata.order_id);
//             alert(response.error.metadata.payment_id);
//         });
//         rzp1.open();

//     } catch (err) {
//         const errors = err.response.data.errors;
//         console.log(errors);
//         if (errors) {
//             errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
//         }
//         dispatch({
//             type: ORDER_ERROR,
//         });
//     }

// }


export const createOrder = ({ clientID, email, mob, amount, currency, bookItems, book_time, book_date, account }) => async dispatch => {
    // const { businessName } = useParams();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    }
    const body = JSON.stringify({ clientID, email, mob, amount, currency, bookItems, book_time, book_date, account });

    console.log(body);
    try {
        const response = await axios.post('api/stripepay/order', body, config, { withCredentials: true });
        console.log(response);
        dispatch({
            type: ADD_ORDER,
            payload: response.data
        });

        window.location.href = response.data.url;
    } catch (err) {
        const errors = err.response.data.errors;
        console.log(err);
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: ORDER_ERROR,
        });
    }

}

export const paymentSuccess = sessionid => async dispatch => {
    try {
        const response = await axios.get(`api/stripepay/${sessionid}`);
        console.log(response);
        dispatch({ type: ORDER_SUCCESS, payload: response.data });
    } catch (error) {
        console.log(error);
        dispatch({
            type: ORDER_FAILURE,
        });
    }
}