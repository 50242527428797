import {
    GET_WEEK_DAYS, WEEK_DAY_ERROR, UPDATE_WEEK_DAYS
} from '../actions/types';


const initialState = { day_weeks: [], day_week: null, loading: true, error: {} }

export default function dayWeek(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_WEEK_DAYS:
            return {
                ...state,
                day_weeks: payload,
                loading: false
            }
        case UPDATE_WEEK_DAYS:
            return {
                ...state,
                day_weeks: [...state.day_weeks, payload],
                loading: false
            }
        case WEEK_DAY_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }

}