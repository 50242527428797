import React, { Fragment, useState, useContext, useEffect } from 'react'
import { connect } from 'react-redux';
import { getClientWiseServices } from '../../actions/category_wise_service';
import Spinner from '../layouts/Spinner';
import { logout } from '../../actions/auth';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { FaCheck } from "react-icons/fa6";
import PropTypes from 'prop-types'
import Categories from './Categories';
import SelectedServices from './SelectedServices';
import ValueContext from '../layouts/ValueContext';

const Services = ({ getClientWiseServices, categorywise_service: { category_wise_services, loading } }) => {
    const { refreshValue } = useContext(ValueContext);

    const [currency, setCurrency] = useState('');
    //GET Param - business name
    const { businessName } = useParams();

    //Decleration -> getClientWiseServices API data
    const [rows, setRows] = useState([]);

    const [isChecked, setIsChecked] = useState(() => {
        return JSON.parse(localStorage.getItem('checkBoxStatus')) || []
    });

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    //Decleration -> Storing locally
    const [addToBag, setAddToBag] = useState(() => {
        return JSON.parse(localStorage.getItem('addToBag')) || []
    });


    const [hours, setHours] = useState(() => {
        return JSON.parse(localStorage.getItem('totalHours'));
    });

    const [totalBill, setTotalBill] = useState(() => {
        return JSON.parse(localStorage.getItem('totalBill'));
    });
    // use effect for api data
    useEffect(() => {
        if (category_wise_services) {
            let result = [];
            category_wise_services.map((categorywise) => (
                result.push(categorywise)
            ));
            setRows(result);
        }
    }, [category_wise_services, setRows]);


    // use effect for get local storage
    useEffect(() => {
        setCurrency(localStorage.getItem('currencySymbol'));

        // Load tasks from local storage when the component mounts
        const storedServices = localStorage.getItem('addToBag');
        if (storedServices) {
            setAddToBag(JSON.parse(storedServices));
        }

        // Load hours from local storage when the component mounts
        const storedTotalHours = localStorage.getItem('totalHours');
        if (storedTotalHours) {
            setHours(JSON.parse(storedTotalHours));
        }

        // Load total bill from local storage when the component mounts
        const storedTotalBilled = localStorage.getItem('totalBill');
        if (storedTotalBilled) {
            setTotalBill(JSON.parse(storedTotalBilled));
        }


        // Load selected service from local storage when the component mounts
        const storedSelectedService = localStorage.getItem('selectedService');
        if (storedSelectedService) {
            setTotalBill(JSON.parse(storedSelectedService));
        }


        const storedCheckboxState = localStorage.getItem('checkBoxStatus');
        if (storedCheckboxState) {
            setIsChecked(JSON.parse(storedCheckboxState));
        }
    }, []);

    useEffect(() => {
        // Update local storage whenever  change
        localStorage.setItem('addToBag', JSON.stringify(addToBag));

        localStorage.setItem('businessName', businessName);

        // Update local storage whenever  change
        localStorage.setItem('totalHours', JSON.stringify(hours));

        // // Update local storage whenever  change
        // localStorage.setItem('totalMinutes', JSON.stringify(minute));

        // Update local storage whenever  change
        localStorage.setItem('totalBill', JSON.stringify(totalBill));

        localStorage.setItem('checkBoxStatus', JSON.stringify(isChecked));
    }, [addToBag, hours, totalBill, isChecked, businessName]);



    // click event for Adding service to local storage
    const addService = (service, e) => {
        const storedServices = JSON.parse(localStorage.getItem('addToBag')) || [];

        const service_item = {
            id: service.sid,
            name: service.serviceName,
            duration: (service.hrs * 60) + service.min,
            price: service.price,
            categoryId: service.categoryTypeId
        };


        const item = JSON.parse(localStorage.getItem('checkBoxStatus')) || [];

        if (e.target.checked === true) {
            const updatedCheckbox = [...isChecked, e.target.id];
            setIsChecked(updatedCheckbox);
            const updatedService = [...addToBag, service_item];

            setHours(updatedService.reduce((a, v) => a = a + v.duration, 0));
            setTotalBill(updatedService.reduce((a, v) => a = a + v.price, 0));
            setAddToBag(updatedService);
            refreshValue((addToBag.length) + 1);
        } else {
            let index = storedServices.findIndex(s => s.name === service.serviceName);

            let checkbox_index = item.findIndex(s => s.name === e.target.id);
            const updatedCheckbox = [...isChecked];
            updatedCheckbox.splice(checkbox_index, 1);
            setIsChecked(updatedCheckbox);


            const updatedService = [...addToBag];
            updatedService.splice(index, 1);
            console.log(hours - ((service.hrs * 60) + service.min));
            setHours(hours - ((service.hrs * 60) + service.min));

            service.price > 0 && setTotalBill(totalBill - service.price);
            setAddToBag(updatedService);
            refreshValue((addToBag.length) - 1);
        }

    }

    useEffect(() => {
        getClientWiseServices(businessName);
    }, [getClientWiseServices, businessName])

    return (
        <Fragment>
            <div className="banner">
                <div className="container ">
                    <div className="col-md-12">
                        <p className="heading">Select Services</p>
                    </div>
                </div>
            </div>
            <section id="contentarea" className='py-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 d-none d-md-block">
                            <Categories />
                        </div>
                        <div className="col-md-7">
                            {loading ? <Spinner /> :
                                rows.map((categorywise) => (
                                    <div id={`cat-${categorywise.id}`}>
                                        <h2 className="py-3 d-md-none fs-6 fw-bold" >{categorywise.categoryName}</h2>
                                        <div className="listtxt card mb-4">
                                            <h2 className="px-4 py-3 d-none d-md-block" id={`cat-${categorywise.id}`}>{categorywise.categoryName}</h2>
                                            {categorywise.services ?
                                                categorywise.services.map((service, index) => (
                                                    <div className="rowlistitems" key={service.sid}>
                                                        <div className="row g-0">
                                                            <div className="col-md-4 col-6 px-4 p-2 py-3">{service.serviceName}

                                                            </div>
                                                            <div className="col-md-3 p-2 d-none d-md-block py-3">{service.hrs ? `${service.hrs} hr` : ''} {service.min ? `${service.min} min` : ''}</div>
                                                            <div className="col-md-3 col-2 p-md-3 py-2">{currency}{service.price}</div>
                                                            <div className="col-md-2 col-4 p-2 pe-4 text-end">
                                                                <div className="select">
                                                                    <label className="">
                                                                        <input type="checkbox" id={`check-${service.sid}`} value={`check-${service.sid}`} checked={isChecked.includes(`check-${service.sid}`)}
                                                                            onChange={(e) => addService(service, e)} />
                                                                        <span className="label">Select</span>
                                                                        <span className="label"><FaCheck /></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                )) : <div>
                                                </div>}

                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div className="col-md-3 d-none d-md-block">
                            <div className="thirdcol card ">
                                <h3 className="p-3">Review</h3>
                                <SelectedServices services={addToBag} totalHours={hours} totalBill={totalBill} currency={currency} />
                                <div className="row g-0 p-2">

                                    <Link to={`/${businessName}/availability/${formattedDate}`} className={`btn addbtn py-3 ${addToBag.length > 0 ? '' : 'disabled'} `}  >Continue to Schedule</Link>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="fixed-bottom d-md-none" id="review-section">
                    <div className="bg-dark p-3 text-light">
                        <div className="d-flex justify-content-around align-items-center">
                            <div className="p-2"><span className="fw-bold">{`${addToBag.length > 0 ? addToBag.length : ''}`}</span> </div>
                            <div className="p-2"> <span className='fw-bold'>{hours ? `${Math.floor(hours / 60) + 'hr : ' + hours % 60}Mins` : ''}</span> </div>
                            <div className='p-2 fw-bold'>{`${addToBag.length > 0 ? '$' + totalBill : ''}`}</div>
                            <Link to={`/${businessName}/availability/${formattedDate}`} className={`btn addbtn ${addToBag.length > 0 ? '' : 'disabled'} text-light border border-light `}  >Schedule</Link>
                        </div>
                    </div>
                </div>
            </section>

        </Fragment>
    )
}
Services.propTypes = {
    categorywise_service: PropTypes.object.isRequired,
    getClientWiseServices: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
    categorywise_service: state.category_wise_service
});

export default connect(mapStateToProps, { getClientWiseServices, logout })(Services);



