import React, { useState, useEffect, useContext } from 'react'
import { FaBagShopping, FaUser, FaCalendarDays, FaArrowRightFromBracket } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout, getClientInfo } from '../../actions/auth';
import PropTypes from 'prop-types';
import ValueContext from '../layouts/ValueContext';
import { base_url } from '../../actions/constants';


const Header = ({ auth: { isAuthenticated, user }, logout, getClientInfo, client_info: { client } }) => {
    const { value } = useContext(ValueContext);
    const [isOpen, setIsOpen] = useState(false);

    const [bName, setbName] = useState('');
    //Decleration -> Storing locally
    const [cart, setCart] = useState(value);
    const [clientInfoData, setClientInfo] = useState({
        firstName: '',
        lastName: '',
        brand_logo: '',
        businessName: '',
        currencySymbol:'',
    });

    const toggleMenu = () => {
        setIsOpen((open) => !open);
    }

    const closeMenu = () => {
        setIsOpen(false);
    };
    useEffect(() => {
        if (value)
            localStorage.setItem('cartValue', value);
        setCart(localStorage.getItem('cartValue'));

    }, [value]);

    useEffect(() => {
        const storedBusinessName = localStorage.getItem('businessName');
        if (storedBusinessName) {
            console.log(storedBusinessName);
            setbName(storedBusinessName);
        }

        
    }, []);


    useEffect(() => {
        getClientInfo(bName);
    }, [getClientInfo, bName]);


    useEffect(() => {
        if (client) {
            localStorage.setItem('currencySymbol',client.currencySymbol);
            setClientInfo({
                firstName: client.firstName ? client.firstName : '',
                lastName: client.lastName ? client.lastName : '',
                brand_logo: client.brand_logo ? client.brand_logo : '',
                businessName: client.businessName ? client.businessName : '',
            });
        }

    }, [client]);

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light py-md-4 py-3" id="services">
                <div className="container">
                    <Link to={isAuthenticated ? `/${bName}/dashboard` : 'https://spania.site'} className="navbar-brand spaniain"> {clientInfoData.brand_logo ? <img src={`${base_url}uploads/${clientInfoData.brand_logo}`} alt="logo" className='' height="60px" /> : clientInfoData.businessName} </Link>
                    {isAuthenticated && <Link onClick={logout} className="ms-auto text-dark  d-md-none" to="#!"> <FaArrowRightFromBracket /></Link>
                    }
                    <Link to={isAuthenticated ? `/${bName}/checkout` : `/${bName}/login`} className='ms-auto me-2 text-dark d-md-none'> {cart && cart} <FaBagShopping /></Link>
                    <button className={`navbar-toggler ${isOpen ? 'expanded' : ''}`} type="button" onClick={toggleMenu}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse`} id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2  mb-lg-0">
                            {isAuthenticated ? <Link to={`/${bName}/dashboard`} className='me-4'><FaUser /> {`${user && user.firstName}  ${user && user.lastName}`}</Link> :
                                <li className="nav-item  "> <Link to={`/${bName}/login`} className='me-4 text-dark'><FaUser /> Sign in</Link></li>
                            }
                            <li className="nav-item"> <Link to={`/${bName}`} className='me-4 text-dark'><FaCalendarDays />Buy</Link></li>
                            <li className="nav-item"><Link to={isAuthenticated ? `/${bName}/checkout` : `/${bName}/login`} className=' text-dark d-none d-md-block'> {cart ? cart : value} <FaBagShopping /></Link></li>
                            {isAuthenticated && <li className="nav-item"><Link onClick={logout} className="ms-4 text-dark d-none d-md-block" to="#!"> <FaArrowRightFromBracket /> Logout</Link></li>
                            }

                        </ul>
                    </div>
                </div>
            </nav>
            {/* Mobile menu */}
            <div className={`collapse1 navbar-collapse d-md-none ${isOpen ? 'is-open' : ''}`} id="navbarSupportedContent1" onClick={closeMenu}>
                <ul className="navbar-nav ms-auto mb-2  mb-lg-0" >
                    <li className="nav-item" onClick={closeMenu}> <Link to={`/${bName}`} className=''>Buy</Link></li>
                    {isAuthenticated && <li className="nav-item" onClick={closeMenu}><Link to={`/${bName}/dashboard`}> Home</Link>
                    </li>}
                    {isAuthenticated && <li className='' onClick={closeMenu}><Link
                    > Appointments</Link>
                        <ul className='ps-4 mt-3 submenu-items'>
                            <li className='nav-item' onClick={closeMenu}><Link to={`/${bName}/upcoming-appointments`} >Upcoming Appointments</Link></li>
                            <li className='nav-item' onClick={closeMenu}><Link to={`/${bName}/past-appointments`} >Past Appointments</Link></li>
                            <li className='nav-item' onClick={closeMenu}><Link to={`/${bName}/cancelled-appointments`} >Cancelled Appointments</Link></li>
                        </ul>
                    </li>}
                    {isAuthenticated && <li className="nav-item" onClick={closeMenu}><Link to={`/${bName}/account-settings`}> Account Settings</Link>
                    </li>}
                </ul>
            </div>

        </>

    )
}
Header.propTypes = {
    logout: PropTypes.func.isRequired,
    clientInfo: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    client_info: state.client_info
});


export default connect(mapStateToProps, { getClientInfo, logout })(Header)
