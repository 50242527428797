import React, { useState } from 'react';
import ValueContext from './ValueContext';

const ValueProvider = ({ children }) => {
    const [value, setValue] = useState('');

    const refreshValue = (newValue) => {
        // Update the value in the state
        setValue(newValue);
    };


    return (
        <ValueContext.Provider value={{ value, refreshValue }}>
            {children}
        </ValueContext.Provider>
    );
};

export default ValueProvider;
