import React, { Fragment, useEffect, useContext, useState } from 'react'
import { FaTrashCan } from "react-icons/fa6";
import ValueContext from '../layouts/ValueContext';


const ReviewServicesInMobile = ({ currency }) => {
    const { refreshValue } = useContext(ValueContext);

    //Decleration -> Storing locally
    const [addToBag, setAddToBag] = useState(() => {
        return JSON.parse(localStorage.getItem('addToBag')) || []
    });
    const [formattedSelDate, setFormattedFullDate] = useState(() => {
        return JSON.parse(localStorage.getItem('scheduleDate'))
    });
    const [time, setTime] = useState(() => {
        return JSON.parse(localStorage.getItem('scheduleTime'))
    });
    const [isChecked, setIsChecked] = useState(() => {
        return JSON.parse(localStorage.getItem('checkBoxStatus')) || []
    });
    useEffect(() => {
        // Update local storage whenever  change
        localStorage.setItem('addToBag', JSON.stringify(addToBag));

    }, [addToBag]);
    const [totalBill, setTotalBill] = useState(() => {
        return JSON.parse(localStorage.getItem('totalBill'));
    });
    useEffect(() => {
        const storedServices = localStorage.getItem('addToBag');
        if (storedServices) {
            setAddToBag(JSON.parse(storedServices));
        }
        // Load schedule date from local storage when the component mounts
        const storedScheduleTime = localStorage.getItem('scheduleTime');
        if (storedScheduleTime) {
            setTime(JSON.parse(storedScheduleTime));
        }

        // Load schedule full date from local storage when the component mounts
        const storedScheduleFullDate = localStorage.getItem('scheduleFullDate');
        if (storedScheduleFullDate) {
            const date = new Date(JSON.parse(storedScheduleFullDate));
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            setFormattedFullDate(date.toLocaleDateString('en-US', options));
        }

        const storedCheckboxState = localStorage.getItem('checkBoxStatus');
        if (storedCheckboxState) {
            setIsChecked(JSON.parse(storedCheckboxState));
        }
        // Load total bill from local storage when the component mounts
        const storedTotalBilled = localStorage.getItem('totalBill');
        if (storedTotalBilled) {
            setTotalBill(JSON.parse(storedTotalBilled));
        }
    }, []);

    const deleteItem = (index) => {

        //LOCAL CHECK BOX REMOVE
        const updatedCheckbox = [...isChecked];
        updatedCheckbox.splice(index, 1);
        localStorage.setItem('checkBoxStatus', JSON.stringify(updatedCheckbox));
        setIsChecked(updatedCheckbox);

        //LOCAL ITEM REMOVE
        const updatedService = [...addToBag];
        console.log(updatedService[index].price);
        const finalBill = totalBill - updatedService[index].price;
        localStorage.setItem('totalBill', JSON.stringify(finalBill));
        setTotalBill(finalBill);
        updatedService.splice(index, 1);
        localStorage.setItem('addToBag', JSON.stringify(updatedService));
        setAddToBag(updatedService);
        refreshValue((addToBag.length) - 1);
    }
    return (
        <Fragment>
            <div >
                {addToBag.length > 0 ?
                    addToBag.map((localService, index) => (
                        <div className="d-flex justify-content-between p-3 border-bottom" key={index}>
                            <div className="mobileCheckout">
                                <div className='fw-bold'>{localService.name}</div>
                                <div className='text-secondary'>{`${Math.floor(localService.duration / 60)} H : ${localService.duration % 60} Min `}</div>
                                <div className='fw-bold mt-2'>{formattedSelDate} {time}</div>
                                <div className='text-secondary'>Appointment Time</div>
                            </div>
                            <div>{currency}{localService.price}</div>
                            <div onClick={() => deleteItem(index)}><span className='text-danger'><FaTrashCan /></span></div>
                        </div>
                    ))
                    : <h4 className='text-center p-4 w-100'>No Appointments </h4>

                }
            </div>
        </Fragment>
    )
}

export default ReviewServicesInMobile
