import axios from 'axios';
import {
    GET_CATEGORIES, CATEGORY_ERROR
} from './types';

import { base_url } from './constants';

axios.defaults.baseURL = base_url;
//GET Categories
export const getCategories = (businessName) => async dispatch => {
    try {
        const res = await axios.get(`/api/client_categories/${businessName}`);
        dispatch({ type: GET_CATEGORIES, payload: res.data });
    } catch (err) {
        dispatch({
            type: CATEGORY_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}


