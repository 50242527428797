import axios from 'axios';
import {
    CANCEL_BOOKING_ERROR, SUCCESS_CANCEL_BOOKING, GET_CANCEL_BOOKINGS
} from './types';
import { setAlert } from './alert';

// Cancell Bookings
export const cancelBooking = ({ paymentId, book_id }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ book_id });
    try {
        const res = await axios.post(`api/stripepay/cancel/${paymentId}`, body, config);
        console.log(res.data);
        dispatch(setAlert('Successfully Cancelled', 'success'));
        dispatch({ type: SUCCESS_CANCEL_BOOKING, payload: res.data });
    } catch (error) {
        dispatch({
            type: CANCEL_BOOKING_ERROR,
        });
    }
}

//GET Cancelled Appointments
export const getCancelledAppointments = () => async dispatch => {
    try {
        const res = await axios.get(`/api/appointments/cancelled`);
        dispatch({ type: GET_CANCEL_BOOKINGS, payload: res.data });
    } catch (err) {
        dispatch({
            type: CANCEL_BOOKING_ERROR,
        });
    }
}