import {
    GET_SERVICES, ADD_SERVICE, SERVICE_ERROR
} from '../actions/types';


const initialState = { posts: [], post: null, loading: true, error: {} }

export default function service(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SERVICES:
            return {
                ...state,
                posts: payload,
                loading: false
            }
        case ADD_SERVICE:
            return {
                ...state,
                posts: [...state.posts, payload],
                loading: false
            }
        case SERVICE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }

}