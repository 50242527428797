import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Spinner from '../layouts/Spinner';
import { logout } from '../../actions/auth';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import { getCategories } from '../../actions/category';

const Categories = ({ isAuthenticated, getCategories, client_categories: { categories, loading } }) => {

    const { businessName } = useParams();
    useEffect(() => {
        getCategories(businessName);
    }, [getCategories, businessName])
    return (
        <div className="sidebar">
            <ul className="list-group list-group-flush">
                {loading ? <Spinner /> :
                    categories.map((category, index) => (
                        <li className="list-group-item" key={index}>
                            <a href={`#cat-${category.id}`}>{category.categoryName}</a>
                        </li>
                    ))}
            </ul>
        </div>
    )
}

Categories.propTypes = {
    logout: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    client_categories: PropTypes.object.isRequired,
    getCategories: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    client_categories: state.category
});

export default connect(mapStateToProps, { getCategories, logout })(Categories);
